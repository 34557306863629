import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { USERS_LIST_INITIAL_VALUES } from '@/lib/constants';
import { InitialState } from '../types/users.types';
import { UserListBody } from '../../interface/users.interface';

const initialState: InitialState = {
  usersList: USERS_LIST_INITIAL_VALUES,
  isLoading: false,
  isResendInviteModalOpen: false,
  usersListBody: null,
  inviteUser: {
    inviteUser: false,
    resendInvite: false
  }
};

const getUsersList = (
  state: InitialState,
  action: PayloadAction<UserListBody>
) => state;

const setUsersList = (
  state: InitialState,
  action: PayloadAction<InitialState['usersList']>
) => ({
  ...state,
  usersList: action.payload,
});

const setIsLoading = (
  state: InitialState,
  action: PayloadAction<InitialState['isLoading']>
) => ({
  ...state,
  isLoading: action.payload,
});

const setUsersListBody = (
  state: InitialState,
  action: PayloadAction<InitialState['usersListBody']>
) => ({
  ...state,
  usersListBody: action.payload,
});

const getUsersLimit = (state: InitialState, action: PayloadAction<void>) =>
  state;

const setIsResendInviteModalState = (
  state: InitialState,
  action: PayloadAction<InitialState['isResendInviteModalOpen']>
) => ({
  ...state,
  isResendInviteModalOpen: action.payload,
});

const setInviteUser = (state: InitialState, action: PayloadAction<{    inviteUser: boolean,
  resendInvite: boolean}>) => ({
    ...state,
    inviteUser: action.payload
  })

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsersList,
    setUsersList,
    getUsersLimit,
    setIsLoading,
    setUsersListBody,
    setIsResendInviteModalState,
    setInviteUser
  },
});

export default usersSlice;
