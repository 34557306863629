const LoaderSpinner = (): JSX.Element => {
  return (
    <>
      <div className="equity-spinner">
        <div className="spinner"></div>
      </div>
    </>
  );
};

export default LoaderSpinner;
