import { useAppSelector } from "@/hooks/useRedux";
import { USER_ROLES } from "@/lib/constants";
import { LOGIN, ORGANIZATIONS, ROOT_PAGE } from "@/src/routes/routesConstants";
import { RootState } from "@/store/index";
import { Navigate } from "react-router";
import ScreenLoader from "@/components/ScreenLoader";

const RootPage = (): JSX.Element => {
  const { authMeData, isAuthenticated, isAuthMeLoading } = useAppSelector((state: RootState) => state.auth);

  const handleRouting = (role: string): string => {
    if (role === USER_ROLES.SUPER_ADMIN) {
      return ORGANIZATIONS;
    }
    return ROOT_PAGE;
  };

  if (isAuthMeLoading && !authMeData.role.name) return <ScreenLoader />;
  else
    return isAuthenticated && authMeData?.role?.name ? (
      <Navigate to={handleRouting(authMeData?.role?.name)} />
    ) : (
      <Navigate to={LOGIN} />
    );
};

export default RootPage;
