import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ADD_ORGANIZATION_INITIAL_VALUES,
  ORGANIZATION_LIST_INITIAL_VALUES,
} from '@/lib/constants';

import {
  InitialState,
  InviteOrganizationBodyTypes,
} from '../types/organization.types';

import {
  OrganizationListBody,
  PaymentTypes,
} from '../../interface/organization.interface';

import { SubscriptionTypeEnums } from '../../../../enums/enums';

const initialState: InitialState = {
  organizationList: ORGANIZATION_LIST_INITIAL_VALUES,
  isLoading: false,
  organizationListBody: null,
  isPaymentTypesLoading: false,
  paymentTypes: [],
  addOrganizationBody: ADD_ORGANIZATION_INITIAL_VALUES,
  subscriptionType: SubscriptionTypeEnums.ORGANIZATION_ADMIN,
  isInviteOrganizationLoading: false,
  orgPlans: [],
  orgPlansLoading: false,
  historyLoading: false,
  history: [],
  orgLoading: false,
  orgDetails: null,
  historyPage: 0,
  historyLastPage: 0,
};

const getOrganizationsList = (
  state: InitialState,
  action: PayloadAction<OrganizationListBody>
) => state;

const setOrganizationsList = (
  state: InitialState,
  action: PayloadAction<InitialState['organizationList']>
) => ({
  ...state,
  organizationList: action.payload,
});

const setIsLoading = (
  state: InitialState,
  action: PayloadAction<InitialState['isLoading']>
) => ({
  ...state,
  isLoading: action.payload,
});

const setOrganizationListBody = (
  state: InitialState,
  action: PayloadAction<InitialState['organizationListBody']>
) => ({
  ...state,
  organizationListBody: action.payload,
});

const getPaymentTypesList = (state: InitialState) => state;

const setPaymentTypesList = (
  state: InitialState,
  action: PayloadAction<PaymentTypes[]>
) => ({
  ...state,
  paymentTypes: action.payload,
});

const setIsPaymentTypesLoading = (
  state: InitialState,
  action: PayloadAction<boolean>
) => ({
  ...state,
  isPaymentTypesLoading: action.payload,
});

const setAddOrganizationBody = (
  state: InitialState,
  action: PayloadAction<{
    organization_id: string | null;
    organization_payment_type_details: {
      id: string;
      name: string;
    } | null;
  }>
) => ({ ...state, addOrganizationBody: action.payload });

const setSubscriptionType = (
  state: InitialState,
  action: PayloadAction<
    'ORG_ADMIN_ONLY' | 'LOAN_OFFICER_ONLY' | 'ORG_ADMIN_LOAN_OFFICER' | string
  >
) => ({
  ...state,
  subscriptionType: action.payload,
});

const inviteOrganization = (
  state: InitialState,
  action: PayloadAction<InviteOrganizationBodyTypes>
) => state;

const isInviteOrganizationLoading = (
  state: InitialState,
  action: PayloadAction<boolean>
) => ({ ...state, isInviteOrganizationLoading: action.payload });
const getOrgDetails = (state: InitialState, action: PayloadAction<string>) =>
  state;

const setOrgDetails = (
  state: InitialState,
  action: PayloadAction<InitialState['orgDetails']>
) => ({
  ...state,
  orgDetails: action.payload,
});

const setOrgLoading = (
  state: InitialState,
  action: PayloadAction<InitialState['orgLoading']>
) => ({
  ...state,
  orgLoading: action.payload,
});

const setOrgHistory = (
  state: InitialState,
  action: PayloadAction<InitialState['history']>
) => ({
  ...state,
  history: action.payload,
});

const setOrgHistoryLoading = (
  state: InitialState,
  action: PayloadAction<InitialState['historyLoading']>
) => ({
  ...state,
  historyLoading: action.payload,
});

const getOrgHistory = (
  state: InitialState,
  action: PayloadAction<{
    id: string;
    page: number;
  }>
) => state;

const setOrgPlans = (
  state: InitialState,
  action: PayloadAction<InitialState['orgPlans']>
) => ({
  ...state,
  orgPlans: action.payload,
});

const setOrgPlansLoading = (
  state: InitialState,
  action: PayloadAction<InitialState['orgPlansLoading']>
) => ({
  ...state,
  orgPlansLoading: action.payload,
});

const getOrgPlans = (state: InitialState, action: PayloadAction<string>) =>
  state;

const resetOrganizationHistory = (state: InitialState) => ({
  ...state,
  historyLoading: false,
  history: [],
  historyPage: 0,
  historyLastPage: 0,
});

const setHistoryPage = (
  state: InitialState,
  { payload }: PayloadAction<{ historyPage?: number; historyLastPage?: number }>
) => ({
  ...state,
  historyPage:
    payload.historyPage !== undefined ? payload.historyPage : state.historyPage,
  historyLastPage:
    payload.historyLastPage !== undefined
      ? payload.historyLastPage
      : state.historyLastPage,
});

const organizationSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    getOrganizationsList,
    setOrganizationsList,
    setIsLoading,
    setOrganizationListBody,
    getPaymentTypesList,
    setIsPaymentTypesLoading,
    setPaymentTypesList,
    setAddOrganizationBody,
    setSubscriptionType,
    inviteOrganization,
    isInviteOrganizationLoading,
    getOrgDetails,
    setOrgDetails,
    setOrgLoading,
    setOrgHistory,
    setOrgHistoryLoading,
    getOrgHistory,
    setOrgPlans,
    setOrgPlansLoading,
    getOrgPlans,
    resetOrganizationHistory,
    setHistoryPage,
  },
});

export default organizationSlice;
