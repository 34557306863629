const LOSSettings = (): JSX.Element => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.49992 0.833496V3.3335M12.4999 0.833496V3.3335M7.49992 16.6668V19.1668M12.4999 16.6668V19.1668M16.6666 7.50016H19.1666M16.6666 11.6668H19.1666M0.833252 7.50016H3.33325M0.833252 11.6668H3.33325M4.99992 3.3335H14.9999C15.9204 3.3335 16.6666 4.07969 16.6666 5.00016V15.0002C16.6666 15.9206 15.9204 16.6668 14.9999 16.6668H4.99992C4.07944 16.6668 3.33325 15.9206 3.33325 15.0002V5.00016C3.33325 4.07969 4.07944 3.3335 4.99992 3.3335ZM7.49992 7.50016H12.4999V12.5002H7.49992V7.50016Z"
          stroke="#344054"
          stroke-width="1.67"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default LOSSettings;
