import * as Yup from 'yup';

// Regex validations
export const emailValidation =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const passwordValidation =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

// Validations using newRegex()
export const _UppercaseRegex = new RegExp('(?=.*[A-Z])');
export const _LowercaseRegex = new RegExp('(?=.*[a-z])');
export const _NumberRegex = new RegExp('(?=.*[0-9])');
export const _SpecialCharRegex = new RegExp('(?=.*[!@#$%^&*])');
export const _MinimumLengthRegex = new RegExp('(?=.{8,})');

export const NumberRegex = /^[1-9]\d*$/;
export const PriceRegex = /^\d+(\.\d{0,2})?$/;
export const NameRegex = /^[A-Za-z ]+$/;

export const isAlphanumeric = /^[A-Za-z0-9\s]*$/;

// Yup validation schemas
export const OrgAdminAndLoanOfficerSubscriptionSchema = Yup.object().shape({
  admin_plan_name: Yup.string()
    .required('Plan Name is required')
    .max(100, 'Plan name cannot exceed 100 characters'),
  admin_price_per_month: Yup.string()
    .required('Price is required')
    .max(6, 'Price cannot exceed 6 figures'),
  loan_officer_plan_name: Yup.string()
    .required('Plan Name is required')
    .max(100, 'Plan name cannot exceed 100 characters'),
  loan_officer_price_per_month: Yup.string()
    .required('Price is required')
    .max(6, 'Price cannot exceed 6 figures'),
});

export const LoanOfficerSubscriptionSchema = Yup.object().shape({
  plan_name: Yup.string()
    .required('Plan Name is required')
    .max(100, 'Plan name cannot exceed 100 characters'),
  price_per_month: Yup.string()
    .required('Price is required')
    .max(6, 'Price cannot exceed 6 figures'),
});

export const OrgAdminSubscriptionSchema = Yup.object().shape({
  plan_name: Yup.string()
    .required('Plan Name is required')
    .max(100, 'Plan name cannot exceed 100 characters'),
  user_limit: Yup.string()
    .required('User limit is required')
    .max(6, 'User limit cannot exceed 6 digits'),
  price_per_month: Yup.string()
    .required('Price is required')
    .max(6, 'Price cannot exceed 6 figures'),
});
