import { Navigate, Outlet } from "react-router";
import { LOGIN } from "./routesConstants";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import ScreenLoader from "@/components/ScreenLoader";

const PrivateRoute = (): JSX.Element => {
  const { isAuthenticated, authMeData, isAuthMeLoading } = useSelector((state: RootState) => state.auth);

  if (isAuthMeLoading) return <ScreenLoader />;
  else return isAuthenticated && authMeData?.role.name ? <Outlet /> : <Navigate to={LOGIN} />;
};

export default PrivateRoute;
