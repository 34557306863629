import { Dispatch, RefObject, SetStateAction } from 'react';

import Skeleton from 'react-loading-skeleton';

import Button from '../Button';

import { InputTypes } from '@/src/enums/enums';

import { PaginationArrayListType } from '@/src/interface/interface';

import ArrowIcon from '@/src/assets/images/svg/arrow_icon.svg';

const Pagination = ({
  pageRef,
  arrayList,
  currentPage,
  limit,
  offset,
  indexOfLastItem,
  totalPages,
  showButtons,
  isLoading,
  setCurrentPage,
}: {
  pageRef: RefObject<HTMLInputElement>;
  arrayList: PaginationArrayListType;
  currentPage: number;
  limit: number;
  offset: number;
  indexOfLastItem: number;
  totalPages: number;
  showButtons: boolean;
  isLoading: boolean;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}): JSX.Element => {
  const handleGoToPage = () => {
    if (
      pageRef.current &&
      parseInt(pageRef.current?.value) <= totalPages &&
      parseInt(pageRef.current?.value) > 0
    )
      setCurrentPage(parseInt(pageRef.current?.value) - 1);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <div className="flex justify-between items-center border-t border-sideNavButtonBg h-14 px-4">
        {!isLoading ? (
          <>
            <div className="flex items-center">
              {!!arrayList?.rows?.length && (
                <span className="text-xs font-medium text-greyBlackLightColor">
                  Page {currentPage + 1} of{' '}
                  {Math.ceil(arrayList?.count / limit)} (Items {offset + 1} to{' '}
                  {indexOfLastItem} of {arrayList?.count})
                </span>
              )}

              <span className="text-xs font-medium text-greyBlackLightColor pl-3">
                Go to page
              </span>
              <input
                type={InputTypes.NUMBER}
                ref={pageRef}
                className="border border-buttonLightGreen rounded-md text-xs px-2 h-7 mx-2 w-9 focus:outline-none"
              />
              <div
                className="flex items-center cursor-pointer"
                onClick={handleGoToPage}
              >
                <span className="text-xs font-medium text-interBlack mr-1">
                  Go
                </span>
                <img src={ArrowIcon} className="w-1" alt="" />
              </div>
            </div>
          </>
        ) : (
          <div className="mb-2">
            <Skeleton count={1} width={330} height={28} borderRadius={10} />
          </div>
        )}

        {!isLoading ? (
          <>
            <>
              {showButtons && (
                <div className="flex items-center">
                  <Button
                    text={'Previous'}
                    className={`text-greyBlackColor text-xs font-medium rounded h-8 w-20 mr-4 ${
                      currentPage === 0
                        ? 'opacity-80 cursor-not-allowed bg-sideNavButtonBg '
                        : 'opacity-100 bg-mainBackground'
                    } `}
                    onClick={() => {
                      if (currentPage !== 0) handlePageChange(currentPage - 1);
                    }}
                    disabled={currentPage === 0}
                  />

                  <Button
                    text={'Next'}
                    className={`bg-mainBackground text-greyBlackColor text-xs font-medium rounded h-8 w-20 ${
                      indexOfLastItem >= arrayList?.count
                        ? 'opacity-80 cursor-not-allowed'
                        : 'opacity-100'
                    }`}
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={indexOfLastItem >= arrayList?.count}
                  />
                </div>
              )}
            </>
          </>
        ) : (
          <>
            <div className="flex items-center mb-2">
              <div className="mr-4">
                <Skeleton count={1} width={80} height={28} borderRadius={10} />
              </div>
              <div>
                <Skeleton count={1} width={80} height={28} borderRadius={10} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Pagination;
