import { useState } from 'react';
import { AxiosError } from 'axios';

import { useAppDispatch } from '@/hooks/useRedux';
import settingSlice from '@/modules/Settings/store/slice/settings.slice';

import { toast } from 'react-toastify';

import Button from '@/components/Button';

import { ResendInviteOrganizationService } from '../../services/organization.services';

import CloseIcon from '../../../../assets/images/svg/close_icon.svg';

const ResendInvite = ({
  resendEmailBody,
}: {
  resendEmailBody: { organization_id: string; email: string };
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  // Open or close the modal
  const handleResendInviteModal = (isOpen: boolean) => {
    dispatch(settingSlice.actions.setResendInviteModalState(isOpen));
  };

  const handleResendEmail = async (): Promise<void> => {
    const body = {
      organization_id: resendEmailBody.organization_id,
      email: resendEmailBody.email,
    };
    try {
      setIsLoading(true);
      const response = await ResendInviteOrganizationService(body);
      if (response) {
        handleResendInviteModal(false);
        toast.success('Resent invitation successfully', { theme: 'colored' });
      }
    } catch (err) {
      toast.error((err as AxiosError).message, { theme: 'colored' });
    }
    setIsLoading(false);
  };

  return (
    <div className="relative transform overflow-hidden bg-white rounded-lg shadow-xl transition-all sm:my-8 w-[348px] font-Inter">
      <div className=" p-6 ">
        <div className="mt-3 text-left">
          <img
            src={CloseIcon}
            alt="*"
            className="cursor-pointer absolute right-5 top-8"
            onClick={() => handleResendInviteModal(false)}
          />
          <h3
            className="text-xl font-semibold leading-6 text-blackDarkColor"
            id="modal-title"
          >
            Resend Invite
          </h3>
          <div className="mt-4">
            <p className="text-sm font-medium text-greyBlackLightColor leading-normal">
              Confirming will resend the invite to the email ID{' '}
              <span className="text-gray-500 font-bold">
                {resendEmailBody?.email}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className=" pt-3 pb-6 flex px-6 w-full">
        <button
          type="button"
          className="inline-flex justify-center items-center rounded h-9 border border-greyLightColor text-sm font-medium text-interBlack w-1/2"
          onClick={() => handleResendInviteModal(false)}
        >
          Cancel
        </button>
        <Button
          text={'Send'}
          type="submit"
          isLoading={isLoading}
          disabled={isLoading}
          onClick={handleResendEmail}
          className="inline-flex w-1/2 ml-3 h-9 border border-buttonLightGreen hover:bg-white hover:text-buttonLightGreen
                bg-buttonLightGreen justify-center items-center text-white text-sm rounded"
        />
      </div>
    </div>
  );
};

export default ResendInvite;
