import { useDispatch } from 'react-redux';
import CloseIcon from '../../../../assets/images/svg/close_icon.svg';
import settingSlice from '@/modules/Settings/store/slice/settings.slice';

const UserLimitModel = (): JSX.Element => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(settingSlice.actions.setUserLimitReachedModalState(false));
  };

  return (
    <>
      <div className="relative transform overflow-hidden bg-white rounded-lg shadow-xl transition-all sm:my-8 w-[441px] font-Inter">
        <div className=" p-6 ">
          <div className="mt-3 text-left">
            <img
              src={CloseIcon}
              alt="*"
              className="cursor-pointer absolute right-4 top-4"
              onClick={handleClose}
            />
            <h3
              className="text-xl text-center font-extrabold leading-6 pt-3 text-blackDarkColor"
              id="modal-title"
            >
              User limit reached!
            </h3>
            <div className="mt-4 text-center">
              <p className="text-xs font-medium text-greyBlackLightColor leading-normal">
                Maximum user limit reached for your current plan.
                <br /> Please remove some users to continue or contact the admin
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserLimitModel;
