import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const PaymentTypesDropDownLoading = ({
  arrayLength,
}: {
  arrayLength: number;
}): JSX.Element => {
  // To modify the height of the loader dropdown between the cases of loan officer only payments and all payments
  const loanOfficerOnly = false;

  return (
    <>
      <div className="absolute w-[93%] shadow-card rounded-lg py-1 bg-white flex flex-col z-50 border">
        <div
          className={`mx-1 rounded-md ${
            loanOfficerOnly ? 'min-h-6' : 'min-h-14'
          } max-h-32 overflow-y-auto`}
        >
          {Array(arrayLength)
            .fill(1)
            .map(() => (
              <div
                key={Math.random()}
                className="h-[30px] w-full text-xs text-blackLightColor flex flex-col justify-center px-3 cursor-pointer"
              >
                <Skeleton count={1} height={15} />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default PaymentTypesDropDownLoading;
