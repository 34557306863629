import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';

import organizationSlice from '../../store/slice/organization.slice';

import Button from '../../../../components/Button';
import PaymentTypesDropDownLoading from './PaymentTypesDropDownLoading';

import { RootState } from '../../../../store';

import {
  PAYMENT_TYPE_VALIDATION_MESSAGE,
  addOrganizationModalHeadings,
} from '../../../../lib/constants';

import SelectDownIcon from '../../../../assets/imagesComponents/SelectDownIcon';
import { PaymentTypes } from '../../../../enums/enums';

const SelectSubscriptionTypesLoanOfficer = ({
  handleCloseModal,
  setCurrentStep,
  setHeaderText,
  setSubHeaderText,
}: {
  handleCloseModal: () => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setHeaderText: Dispatch<SetStateAction<string>>;
  setSubHeaderText: Dispatch<SetStateAction<string>>;
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const { isPaymentTypesLoading, paymentTypes, addOrganizationBody } =
    useAppSelector((state: RootState) => state.organizations);
  const { organization_payment_type_details } = addOrganizationBody;

  const [isPaymentTypesDropdownOpen, setIsPaymentTypesDropdownOpen] =
    useState<boolean>(false);
  const [fieldError, setFieldError] = useState<string | null>(null);
  const [hasSelectedPaymentType, setHasSelectedPaymentType] =
    useState<boolean>(false);

  const handleSelectPaymentType = (item: {
    id: string;
    name: string;
    code: string;
  }): void => {
    const { id, name, code } = item;
    setFieldError(null);
    setIsPaymentTypesDropdownOpen(false);
    dispatch(
      organizationSlice.actions.setAddOrganizationBody({
        ...addOrganizationBody,
        organization_payment_type_details: {
          id,
          name,
        },
      })
    );
    dispatch(organizationSlice.actions.setSubscriptionType(code));
  };

  useEffect(() => {
    setHeaderText(addOrganizationModalHeadings['subscriptionType'].heading);
    setSubHeaderText(
      addOrganizationModalHeadings['subscriptionType'].subHeading
    );
  }, []);

  useEffect(() => {
    if (!paymentTypes?.length)
      dispatch(organizationSlice.actions.getPaymentTypesList());
  }, [paymentTypes]);

  useMemo(() => {
    if (paymentTypes.length) {
      const selectedPayment = paymentTypes?.filter(
        item => item?.code === PaymentTypes.LOAN_OFFICER
      )[0];
      handleSelectPaymentType({
        id: selectedPayment?.id,
        name: selectedPayment?.name,
        code: selectedPayment?.code,
      });
    }
  }, [paymentTypes]);

  const toggleDropdown = (): void => {
    setIsPaymentTypesDropdownOpen(prev => !prev);
  };

  const handleSubmit = (): void => {
    if (!organization_payment_type_details) {
      setFieldError(PAYMENT_TYPE_VALIDATION_MESSAGE);
    } else {
      setFieldError(null);
      setCurrentStep(3);
    }
  };

  return (
    <>
      <div className="w-full">
        <h6 className="text-xs font-semibold mb-2 text-greyWhiteColor">
          Payment type
        </h6>
        <div
          className={`w-full border flex justify-between items-center h-9 rounded first-letter px-4 cursor-pointer ${
            isPaymentTypesDropdownOpen
              ? 'bg-white border-greenLightColor'
              : 'bg-sideNavButtonBg border-greyLightColor'
          }`}
          tabIndex={0}
          onClick={() => toggleDropdown()}
          onKeyDown={e => {
            if (e.key === 'Enter') toggleDropdown();
          }}
          style={{
            borderColor: `${!!fieldError ? '#FF3E1D' : ''}`,
          }}
        >
          <span className="text-xs text-greyWhiteColor">
            <span className="text-xs text-blackDarkColor">
              {`${
                hasSelectedPaymentType
                  ? organization_payment_type_details?.name
                  : 'Loan Officer Only'
              }`}
            </span>
          </span>
          <span
            className={`${
              isPaymentTypesDropdownOpen ? 'rotate-180 transition-all' : ''
            }`}
          >
            <SelectDownIcon />
          </span>
        </div>
        {fieldError && (
          <p className="text-redErrorColor absolute font-normal text-small font-Inter mt-0.287">
            {fieldError}
          </p>
        )}
        {isPaymentTypesDropdownOpen && (
          <>
            {!isPaymentTypesLoading ? (
              <div className="absolute rounded-lg py-1 shadow-card w-[93%] bg-white z-50">
                <div className="mx-1 rounded-md min-h-14 max-h-32 overflow-y-auto">
                  {paymentTypes?.map(item => (
                    <div
                      className="h-[30px] w-full text-xs text-blackDarkColor px-3 flex items-center cursor-pointer hover:bg-gray-200 hover:rounded-md"
                      key={item?.id}
                      tabIndex={0}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          handleSelectPaymentType({
                            id: item?.id,
                            name: item?.name,
                            code: item?.code,
                          });
                          setHasSelectedPaymentType(true);
                        }
                      }}
                      onClick={() => {
                        handleSelectPaymentType({
                          id: item?.id,
                          name: item?.name,
                          code: item?.code,
                        });
                        setHasSelectedPaymentType(true);
                      }}
                    >
                      {item?.name}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <PaymentTypesDropDownLoading arrayLength={3} />
            )}
          </>
        )}
      </div>
      <div className="pt-14 pb-2 flex w-full">
        <Button
          text="Cancel"
          type="button"
          className="inline-flex w-1/2 mr-2 h-46 border border-greyLightColor hover:border-redErrorColor hover:text-redErrorColor justify-center items-center
           text-interBlack text-sm rounded"
          onClick={handleCloseModal}
        />

        <Button
          text="Continue"
          type="submit"
          className={`inline-flex w-1/2 ml-2 h-46 border border-buttonLightGreen ${
            !isPaymentTypesLoading &&
            'hover:bg-white hover:text-buttonLightGreen'
          } ${
            isPaymentTypesLoading
              ? 'opacity-50 cursor-not-allowed'
              : 'cursor-pointer'
          }
             bg-buttonLightGreen justify-center items-center text-white text-sm rounded`}
          onClick={handleSubmit}
          disabled={isPaymentTypesLoading}
        />
      </div>
    </>
  );
};

export default SelectSubscriptionTypesLoanOfficer;
