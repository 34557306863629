import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import Button from '@/components/Button';
import Input from '@/components/Input';

import {
  _LowercaseRegex,
  _MinimumLengthRegex,
  _NumberRegex,
  _SpecialCharRegex,
  _UppercaseRegex,
  passwordValidation,
} from '@/lib/validation';
import { InputTypes, newPasswordCriteria } from '@/src/enums/enums';
import { NewPasswordConditions } from '@/src/interface/interface';

import { LOGIN } from '@/src/routes/routesConstants';
import { PASSWORD_RESET_SUCCESS_MSG } from '@/lib/constants';

import { FirebaseErrorLibrary } from '@/lib/errorCodes';
import { confirmThePasswordReset } from '@/lib/firebase';
import { FirebaseError } from 'firebase/app';

import UnCheckIcon from '@/src/assets/images/svg/round_grey_icon.svg';
import CheckIcon from '../../../../assets/images/svg/round_green_icon.svg';
import Logo from '@/src/equity_release_logo.svg';

const ResetPassword = (): JSX.Element => {
  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const [inputNewPasswordType, setNewPasswordInputType] = useState<string>('');
  const [inputConfirmPasswordType, setConfirmPasswordInputType] =
    useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const [params] = useSearchParams();
  const oobCode = params.get('oobCode');

  useMemo((): void => {
    if (inputNewPasswordType === InputTypes.PASSWORD) {
      setShowNewPassword(true);
    } else if (inputNewPasswordType === InputTypes.TEXT) {
      setShowNewPassword(false);
    } else {
      setShowNewPassword(true);
    }
  }, [inputNewPasswordType]);

  useMemo((): void => {
    if (inputConfirmPasswordType === InputTypes.PASSWORD) {
      setShowConfirmPassword(true);
    } else if (inputConfirmPasswordType === InputTypes.TEXT) {
      setShowConfirmPassword(false);
    } else {
      setShowConfirmPassword(true);
    }
  }, [inputConfirmPasswordType]);

  // Handle submit for reset password
  const onSubmit = async (values: typeof initialValues) => {
    const { password } = values;
    if (oobCode && password)
      try {
        setIsLoading(true);
        await confirmThePasswordReset({ password, oobCode });
        toast.success(PASSWORD_RESET_SUCCESS_MSG, { theme: 'colored' });
        navigate({ pathname: LOGIN }, { replace: true });
      } catch (err) {
        const errorCode = { ...(err as FirebaseError) }.code;
        toast.error(
          FirebaseErrorLibrary.filter(item => item.code === errorCode)[0]
            .message
        );
      }
    setIsLoading(false);
  };

  // Destructuring items from useFormik() hook
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: setNewPasswordSchema,
      onSubmit,
    });

  // Conditions to check each acceptance criteria for newPassword
  const satisfiedCondition = useMemo((): NewPasswordConditions[] => {
    const { password } = values;
    const conditions: NewPasswordConditions[] = [];

    if (_UppercaseRegex.test(password))
      conditions.push(newPasswordCriteria.UPPERCASE);
    if (_LowercaseRegex.test(password))
      conditions.push(newPasswordCriteria.LOWERCASE);
    if (_NumberRegex.test(password))
      conditions.push(newPasswordCriteria.NUMBER);
    if (_SpecialCharRegex.test(password))
      conditions.push(newPasswordCriteria.SPECIAL_CHARACTERS);
    if (_MinimumLengthRegex.test(password))
      conditions.push(newPasswordCriteria.MINIMUM_LENGTH);

    return conditions;
  }, [values.password]);

  return (
    <div className="m-auto w-[367px] shadow-card px-6 pt-8 pb-6 flex flex-col justify-center items-center rounded-lg font-Inter bg-white">
      <img className="w-9 h-9" src={Logo} alt="*" />
      <h1 className="text-xl text-blackDarkColor font-semibold pt-4">
        Set new password
      </h1>
      <small className="text-xs font-medium text-greyBlackLightColor mt-2">
        Please enter a secure password.
      </small>

      <div className="w-full mt-3">
        <div className="mt-4">
          <Input
            placeholder="Enter new password"
            label="Password"
            id="password"
            name="password"
            type={InputTypes.PASSWORD}
            value={values.password}
            labelHide={true}
            inputIcon={true}
            colorLabel="text-interBlack"
            errors={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            onChange={handleChange}
            onBlur={handleBlur}
            inputType={inputNewPasswordType}
            setInputType={setNewPasswordInputType}
            showPasswordToggle={showNewPassword}
            autoFocus
            style={{
              borderColor: `${
                touched.password && errors.password ? '#FF3E1D' : ''
              }`,
            }}
          />
        </div>

        <div className="mt-4">
          <Input
            placeholder="Confirm password"
            label="Confirm Password"
            id="confirmPassword"
            name="confirmPassword"
            type={InputTypes.PASSWORD}
            value={values.confirmPassword}
            labelHide={true}
            inputIcon={true}
            colorLabel="text-interBlack"
            errors={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            inputType={inputConfirmPasswordType}
            setInputType={setConfirmPasswordInputType}
            showPasswordToggle={showConfirmPassword}
            style={{
              borderColor: `${
                touched.confirmPassword && errors.confirmPassword
                  ? '#FF3E1D'
                  : ''
              }`,
            }}
          />
        </div>
      </div>

      <div className="flex flex-col font-medium my-4 w-full">
        <span className="text-greyBlackColor text-xs mb-2">
          Password criteria
        </span>
        <div className="flex w-full">
          <div className="w-1/2 bg-[#F2F4F7] rounded-md flex items-center h[21px] pl-1 mr-1">
            {satisfiedCondition.includes(newPasswordCriteria.LOWERCASE) ? (
              <div>
                <img src={CheckIcon} alt="*" />
              </div>
            ) : (
              <div>
                <img src={UnCheckIcon} alt="*" />
              </div>
            )}
            <span className="text-greyBlackColor text-xs pl-1">
              lowercase character
            </span>
          </div>
          <div className="w-1/2 bg-[#F2F4F7] rounded-md flex items-center h-[21px] pl-1 ml-1">
            {satisfiedCondition.includes(newPasswordCriteria.NUMBER) ? (
              <div>
                <img src={CheckIcon} alt="*" />
              </div>
            ) : (
              <div>
                <img src={UnCheckIcon} alt="*" />
              </div>
            )}
            <span className="text-greyBlackColor text-xs pl-1">number</span>
          </div>
        </div>
        <div className="flex w-full mt-1">
          <div className="w-1/2 bg-[#F2F4F7] rounded-md flex items-center h[21px] pl-1 mr-1">
            {satisfiedCondition.includes(newPasswordCriteria.UPPERCASE) ? (
              <div>
                <img src={CheckIcon} alt="*" />
              </div>
            ) : (
              <div>
                <img src={UnCheckIcon} alt="*" />
              </div>
            )}
            <span className="text-greyBlackColor text-xs pl-1">
              uppercase character
            </span>
          </div>
          <div className="w-1/2 bg-[#F2F4F7] rounded-md flex items-center h-[21px] pl-1 ml-1">
            {satisfiedCondition.includes(
              newPasswordCriteria.SPECIAL_CHARACTERS
            ) ? (
              <div>
                <img src={CheckIcon} alt="*" />
              </div>
            ) : (
              <div>
                <img src={UnCheckIcon} alt="*" />
              </div>
            )}
            <span className="text-greyBlackColor text-xs pl-1">
              special character
            </span>
          </div>
        </div>
        <div className="flex w-full mt-1">
          <div className="w-1/2 bg-[#F2F4F7] rounded-md flex items-center h[21px] pl-1 mr-1">
            {satisfiedCondition.includes(newPasswordCriteria.MINIMUM_LENGTH) ? (
              <div>
                <img src={CheckIcon} alt="*" />
              </div>
            ) : (
              <div>
                <img src={UnCheckIcon} alt="*" />
              </div>
            )}
            <span className="text-greyBlackColor text-xs pl-1">
              minimum 8 characters
            </span>
          </div>
          <div className="w-1/2 bg-[#F2F4F7] rounded-md flex items-center h-[21px] pl-1 ml-1">
            {values.password === values.confirmPassword &&
            values.confirmPassword !== '' ? (
              <div>
                <img src={CheckIcon} alt="*" />
              </div>
            ) : (
              <div>
                <img src={UnCheckIcon} alt="*" />
              </div>
            )}
            <span className="text-greyBlackColor text-xs pl-1">
              confirm password
            </span>
          </div>
        </div>
      </div>

      <Button
        text="Reset Password"
        type="submit"
        disabled={isLoading}
        isLoading={isLoading}
        onClick={e => {
          e.preventDefault();
          handleSubmit();
        }}
        className={`inline-flex w-full mt-7 h-9 border border-buttonLightGreen ${
          !isLoading && 'hover:bg-white'
        }  hover:text-buttonLightGreen bg-buttonLightGreen justify-center items-center text-white text-sm rounded`}
      />
    </div>
  );
};

export default ResetPassword;

const setNewPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'The password must be at least 8 characters')
    .matches(
      passwordValidation,
      'The password criteria is not met. It must contain a mix of letters, numbers, and symbols'
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'The passwords do not match')
    .required('Confirm password is required'),
});
