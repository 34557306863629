import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import CloseIcon from '../../assets/images/svg/close_icon.svg';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Button from '../Button/Button';
import dayjs from 'dayjs';
import { generateCsvService } from '@/modules/Users/service/users.service';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

const schema = Yup.object().shape({
  fromDate: Yup.date().required('From Date is required'),
  toDate: Yup.date()
    .required('To Date is required')
    .min(Yup.ref('fromDate'), 'To Date should be greater than From Date'),
});

interface Props {
  open: boolean;
  onRequestClose: () => void;
}

const DownloadUserModal = (props: Props) => {
  const { open, onRequestClose } = props;

  const formik = useFormik({
    initialValues: {
      fromDate: null,
      toDate: null,
    },
    validationSchema: schema,
    onSubmit: values => {
      console.log(values);
    },
  });

  const [reportType, setReportType] = useState('Default');
  const [loading, setLoading] = useState(false);

  const dateDisabled = reportType === 'Default';

  const handleDownload = async () => {
    // Validate the date range
    if (reportType === 'Date Range') {
      // Validate form
      formik.setTouched({
        fromDate: true,
        toDate: true,
      });
      const formValid = await formik.validateForm();
      if (Object.keys(formValid).length > 0) {
        return;
      }
    }

    setLoading(true);
    try {
      const formValues = formik.values;
      const body: {
        start_date?: string;
        end_date?: string;
      } = {};
      if (reportType === 'Date Range') {
        body['start_date'] = dayjs(formValues.fromDate).format('YYYY-MM-DD');
        body['end_date'] = dayjs(formValues.toDate).format('YYYY-MM-DD');
      }
      await generateCsvService(body);
      toast.success('CSV download successful! Check your email for the file.', {
        bodyClassName: 'text-[#21BD7A]',
        closeButton() {
          return (
            <div className="flex items-center justify-center w-6 h-6 rounded-full text-[#D9D9D9]">
              <img src={CloseIcon} alt="*" />
            </div>
          );
        },
      });
      onRequestClose();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    onRequestClose();
    formik.resetForm();
    setReportType('Default');
  };

  useEffect(() => {
    formik.resetForm();
  }, [reportType]);

  return (
    <ReactModal
      isOpen={open}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      className="mx-auto rounded-lg shadow-modal"
      style={{
        overlay: {
          display: 'flex',
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.61)',
        },
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="relative transform overflow-hidden bg-white rounded-lg shadow-xl transition-all w-[500px] font-Inter">
          <div className="p-6 px-8">
            <div className="mt-3 text-left">
              <img
                src={CloseIcon}
                alt="*"
                className="cursor-pointer absolute right-5 top-6"
                onClick={() => onRequestClose()}
              />
              <h3
                className="text-xl font-semibold leading-6 text-blackDarkColor"
                id="modal-title"
              >
                Download CSV
              </h3>
              <div className="mt-2">
                <p className="text-xs font-medium text-greyBlackLightColor leading-normal">
                  Download Organizations CSV
                </p>
              </div>
            </div>
            <div className="mt-4 flex flex-col w-full">
              <label
                className={`text-xs pb-[10px] h-6 flex items-end font-semibold text-[#7E7E7E]`}
                htmlFor={'reportType'}
              >
                Statement Type
              </label>
              <div className="select relative">
                <select
                  className=" relative border border-greyLightColor rounded py-2 pl-2 pr-9 w-full text-xs h-9 mb-1 focus:outline-none placeholder:text-xs placeholder:text-greyThinLightColor"
                  id="reportType"
                  value={reportType}
                  onChange={e => setReportType(e.target.value)}
                >
                  <option value="Default">Default</option>
                  <option value="Date Range">Date Range</option>
                </select>
              </div>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className="grid grid-cols-2 gap-5">
                <div>
                  <p className="text-xs h-6 flex items-end font-semibold text-[#7E7E7E]">
                    <span className={dateDisabled ? 'opacity-70' : ''}>
                      From Date
                    </span>
                  </p>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      disableFuture
                      slotProps={{
                        textField: {
                          size: 'small',
                          helperText:
                            formik.touched.fromDate && formik.errors.fromDate,
                          error: Boolean(formik.errors.fromDate),
                        },
                      }}
                      value={formik.values.fromDate}
                      onChange={date => {
                        formik.setTouched({ fromDate: true });
                        formik.setFieldValue('fromDate', date);
                      }}
                      disabled={dateDisabled}
                      minDate={dayjs().subtract(6, 'months')}
                    />
                  </DemoContainer>
                </div>
                <div>
                  <p className="text-xs h-6 flex items-end font-semibold text-[#7E7E7E]">
                    <span className={dateDisabled ? 'opacity-70' : ''}>
                      To Date
                    </span>
                  </p>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      disableFuture
                      slotProps={{
                        textField: {
                          size: 'small',
                          helperText:
                            formik.touched.toDate && formik.errors.toDate,
                          error: Boolean(formik.errors.toDate),
                        },
                      }}
                      value={formik.values.toDate}
                      onChange={date => {
                        formik.setTouched({ fromDate: true });
                        formik.setFieldValue('toDate', date);
                      }}
                      disabled={dateDisabled}
                      minDate={dayjs().subtract(6, 'months')}
                    />
                  </DemoContainer>
                </div>
              </div>
              <p className="grid-cols-2 mt-3 text-[#7E7E7E] text-xs font-semibold ml-1">
                * Dates shown in CSV are in UTC
              </p>
            </form>

            <div className="grid grid-cols-2 gap-5 mt-8 mb-5">
              <Button
                type="button"
                className="w-full border border-[#D9D9D9] h-12 rounded-sm"
                onClick={onClose}
                text="Cancel"
              />
              <Button
                type="button"
                className="w-full border border-buttonLightGreen h-12 rounded-sm bg-buttonLightGreen text-white"
                text="Send Email"
                onClick={handleDownload}
                isLoading={loading}
              />
            </div>
          </div>
        </div>
      </LocalizationProvider>
    </ReactModal>
  );
};

export default DownloadUserModal;
