import React from "react";

export function OrganizationsIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_223_15240)">
        <path
          d="M16 14C16 14.552 15.552 15 15 15H14C13.448 15 13 14.552 13 14C13 13.448 13.448 13 14 13H15C15.552 13 16 13.448 16 14ZM19 13H18C17.448 13 17 13.448 17 14C17 14.552 17.448 15 18 15H19C19.552 15 20 14.552 20 14C20 13.448 19.552 13 19 13ZM15 17H14C13.448 17 13 17.448 13 18C13 18.552 13.448 19 14 19H15C15.552 19 16 18.552 16 18C16 17.448 15.552 17 15 17ZM19 17H18C17.448 17 17 17.448 17 18C17 18.552 17.448 19 18 19H19C19.552 19 20 18.552 20 18C20 17.448 19.552 17 19 17ZM15 5H14C13.448 5 13 5.448 13 6C13 6.552 13.448 7 14 7H15C15.552 7 16 6.552 16 6C16 5.448 15.552 5 15 5ZM19 5H18C17.448 5 17 5.448 17 6C17 6.552 17.448 7 18 7H19C19.552 7 20 6.552 20 6C20 5.448 19.552 5 19 5ZM15 9H14C13.448 9 13 9.448 13 10C13 10.552 13.448 11 14 11H15C15.552 11 16 10.552 16 10C16 9.448 15.552 9 15 9ZM19 9H18C17.448 9 17 9.448 17 10C17 10.552 17.448 11 18 11H19C19.552 11 20 10.552 20 10C20 9.448 19.552 9 19 9ZM24 5V19C24 21.757 21.757 24 19 24H5C2.243 24 0 21.757 0 19V9.258C0 7.907 0.558 6.594 1.531 5.657L3.419 3.839C4.586 2.714 6.414 2.715 7.581 3.839L9 5.206V5C9 2.243 11.243 0 14 0H19C21.757 0 24 2.243 24 5ZM9 9.258C9 8.447 8.665 7.66 8.081 7.097L6.193 5.279C5.999 5.091 5.749 4.998 5.5 4.998C5.251 4.998 5.001 5.091 4.807 5.279L2.919 7.097C2.335 7.659 2 8.447 2 9.258V19C2 20.654 3.346 22 5 22H9V9.258ZM22 5C22 3.346 20.654 2 19 2H14C12.346 2 11 3.346 11 5V22H19C20.654 22 22 20.654 22 19V5ZM6 13H5C4.448 13 4 13.448 4 14C4 14.552 4.448 15 5 15H6C6.552 15 7 14.552 7 14C7 13.448 6.552 13 6 13ZM6 9H5C4.448 9 4 9.448 4 10C4 10.552 4.448 11 5 11H6C6.552 11 7 10.552 7 10C7 9.448 6.552 9 6 9ZM6 17H5C4.448 17 4 17.448 4 18C4 18.552 4.448 19 5 19H6C6.552 19 7 18.552 7 18C7 17.448 6.552 17 6 17Z"
          fill="#027A48"
        />
      </g>
      <defs>
        <clipPath id="clip0_223_15240">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default OrganizationsIcon;
