import NoDataIcon from '@/src/assets/images/svg/no-data-search_icon.svg';

const NoUsersFound = ({
  searchInput,
}: {
  searchInput: string;
}): JSX.Element => {
  return (
    <div className="flex flex-col h-full items-center">
      <img className="mt-20" src={NoDataIcon} alt="" />
      <h2 className="text-interBlack text-xl font-medium py-2">
        No data found
      </h2>
      <span className="text-greyBlackColor text-sm font-medium text-center">
        There are no users named{' '}
        <span className="font-semibold">{searchInput}</span> <br /> Please try
        again
      </span>
    </div>
  );
};

export default NoUsersFound;
