import authSlice from '../slice/auth.slice';
import { call, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  ForgotPasswordService,
  GetUserRolesService,
} from '../../service/auth.service';
import {
  AuthMeResponseTypes,
  ForgotPasswordPayload,
} from '../../interface/auth.interface';
import loaderSlice from '@/store/slices/loader.slice';
import { CHECK_MAIL } from '@/src/routes/routesConstants';
import { createSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RESENT_PASSWORD_SUCCESS_MESSAGE } from '@/lib/constants';

function* AuthMeSaga(action: PayloadAction<boolean>) {
  try {
    yield put(authSlice.actions.setIsAuthMeLoading(true));
    const response: AuthMeResponseTypes = yield call(GetUserRolesService);
    if (response) {
      yield put(authSlice.actions.setAuthMe(response));
      if (action.payload) {
        localStorage.setItem('userRole', response?.role?.name);
      } else {
        sessionStorage.setItem('userRole', response?.role?.name);
      }
    }
  } catch (err) {
    const error = (err as AxiosError).message;
  } finally {
    yield put(authSlice.actions.setIsAuthMeLoading(false));
  }
}

function* ForgotPassword(action: PayloadAction<ForgotPasswordPayload>) {
  try {
    yield put(
      loaderSlice.actions.startLoadingAction(
        authSlice.actions.forgotPassword.type
      )
    );
    const { email, navigate } = action.payload;
    const requestBody = { email: email };
    const response: unknown = yield call(ForgotPasswordService, requestBody);
    if (response) {
      if (navigate)
        navigate(
          {
            pathname: `${CHECK_MAIL}/`,
            search: createSearchParams({
              email,
            }).toString(),
          },
          { replace: true }
        );
      toast.success(RESENT_PASSWORD_SUCCESS_MESSAGE, { theme: 'colored' });
    }
  } catch (err) {
    toast.error((err as AxiosError).message, { theme: 'colored' });
  } finally {
    yield put(
      loaderSlice.actions.stopLoadingAction(
        authSlice.actions.forgotPassword.type
      )
    );
  }
}

export default function* authSaga() {
  yield takeEvery(authSlice.actions.getAuthMe.type, AuthMeSaga);
  yield takeEvery(authSlice.actions.forgotPassword.type, ForgotPassword);
}
