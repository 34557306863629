import { useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { LOGIN } from '@/src/routes/routesConstants';
import Button from '@/components/Button';
import Logo from '@/src/equity_release_logo.svg';
import authSlice from '../../store/slice/auth.slice';
import { useDispatch } from 'react-redux';

const CheckMail = (): JSX.Element => {
  const [params] = useSearchParams();
  const email = params.get('email') as string;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleResendPasswordResetMail = async (): Promise<void> => {
    if (email) dispatch(authSlice.actions.forgotPassword({ email }));
    setIsLoading(false);
  };

  return (
    <div className="m-auto w-[367px] shadow-card px-6 pt-8 pb-4 flex flex-col justify-center items-center rounded-lg font-Inter bg-white">
      <img className="w-9 h-9" src={Logo} alt="*" />
      <h1 className="text-xl text-blackDarkColor font-semibold pt-8">
        Check your email
      </h1>

      <small className="text-xs font-medium text-greyBlackLightColor mt-2 text-center">
        We have sent a password reset link to <br />
        {email ? email : '--'}
      </small>

      <div className="flex pt-4 pb-16">
        <span className="text-xs text-greyBlackColor">
          Didn’t receive?
          <Button
            className={`text-greenLightColor cursor-pointer text-xs font-medium pl-1 ${
              isLoading && 'cursor-not-allowed opacity-50'
            }`}
            type="button"
            text="Click to resend"
            disabled={isLoading}
            onClick={handleResendPasswordResetMail}
          />
        </span>
      </div>

      <NavLink to={LOGIN}>
        <a className="text-greenLightColor cursor-pointer text-xs font-medium ">
          Back to login
        </a>
      </NavLink>
    </div>
  );
};

export default CheckMail;
