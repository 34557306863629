import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  Auth,
  getAuth,
  sendPasswordResetEmail,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from 'firebase/auth';
import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
} from './config';
import { FirebaseConfig } from '../interface/interface';

// Firebase configuration
const firebaseConfig: FirebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
  appId: FIREBASE_APP_ID,
  measurementId: FIREBASE_MEASUREMENT_ID,
};

// Function to send password reset mail
export const passwordReset = async (email: string) => {
  return await sendPasswordResetEmail(auth, email);
};

// Function to accept oobCode from reset password mail link and also the new user password to reset password
export const confirmThePasswordReset = async ({
  oobCode,
  password,
}: {
  oobCode: string;
  password: string;
}) => {
  if (!oobCode && !password) return;

  return await confirmPasswordReset(auth, oobCode, password);
};

// Function to validate reset password email oobCode
export const verifyOobCode = async (oobCode: string) => {
  if (!oobCode) return;
  return await verifyPasswordResetCode(auth, oobCode);
};

// Initialize Firebase
const app: FirebaseApp = initializeApp(firebaseConfig);
export const auth: Auth = getAuth(app);
