import { useState, useEffect } from 'react';
import { auth } from '@/lib/firebase';
import { useDispatch } from 'react-redux';
import authSlice from '@/modules/Authentication/store/slice/auth.slice';
import { Outlet, useNavigate } from 'react-router';

export default function AuthRoute() {
  const [checkAuthStatus, setCheckAuthStatus] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        dispatch(authSlice.actions.setIsAuthenticated(true));
        dispatch(authSlice.actions.getAuthMe({ rememberMe: false, navigate }));
      }
      setCheckAuthStatus(true);
    });

    return () => unsubscribe(); // Cleanup
  }, []); // Empty dependency array

  return checkAuthStatus && <Outlet />;
}
