import React from 'react';

const SelectDownIcon = () => {
  return (
    <svg
      width="12"
      height="10"
      viewBox="0 0 12 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="opacity-70"
        d="M6.86603 9.5C6.48113 10.1667 5.51887 10.1667 5.13397 9.5L0.803848 2C0.418948 1.33333 0.900074 0.499999 1.66987 0.499999L10.3301 0.5C11.0999 0.5 11.5811 1.33333 11.1962 2L6.86603 9.5Z"
        fill="#393939"
      />
    </svg>
  );
};

export default SelectDownIcon;
