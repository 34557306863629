import { useState } from 'react';

const ToggleHandler = ({
  options,
  current,
  setCurrentTab,
}: {
  options: string[];
  current: string;
  setCurrentTab: (tab: string) => void;
}) => {
  return (
    <div className={`flex bg-sideNavButtonBg w-full`}>
      {options.map(option => (
        <div
          key={option}
          className={`flex justify-center items-center border cursor-pointer h-12 w-1/2  ${
            current === option
              ? 'border-greenLightColor font-semibold rounded bg-white text-buttonLightGreen'
              : 'border-transparent rounded-tl rounded-bl  font-medium bg-sideNavButtonBg text-[#62646B]'
          }`}
          onClick={current !== option ? () => setCurrentTab(option) : undefined}
        >
          <span className="text-base">{option}</span>
        </div>
      ))}
    </div>
  );
};

export default ToggleHandler;
