import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import AuthLayout from '@/components/Layout/AuthLayout/AuthLayout';
import MainLayout from '@/components/Layout/MainLayout/MainLayout';
import {
  CHECK_MAIL,
  FORGOT_PASSWORD,
  LOGIN,
  ORGANIZATIONS,
  ORGANIZATIONS_DETAILS,
  RESET_PASSWORD,
  ROOT_PAGE,
  USERS,
  VALIDATE_USER,
} from './routesConstants';
import Login from '@/modules/Authentication/pages/Login/Login';
import RootPage from '@/modules/Authentication/pages/RootPage/RootPage';
import OrganizationsList from '@/modules/Organizations/pages/OrganizatonsList/OrganizationsList';
import OrganizationDetails from '@/modules/Organizations/pages/OrganizationDetails/OrganizationDetails';
import AuthRoute from './AuthRoute';
import ForgotPassword from '@/modules/Authentication/pages/Login/ForgotPassword';
import CheckMail from '@/modules/Authentication/pages/Login/CheckMail';
import ValidateUser from '@/modules/Authentication/pages/OrganizationRegistration/ValidateUser';
import ResetPassword from '@/modules/Authentication/pages/Login/ResetPassword';
import UserList from '@/modules/Users/pages/Users/UserList';

const Router = (): JSX.Element => (
  <BrowserRouter>
    <Routes>
      <Route element={<AuthRoute />}>
        <Route path={ROOT_PAGE} element={<RootPage />} />
        <Route element={<PublicRoute />}>
          <Route element={<AuthLayout />}>
            <Route path={LOGIN} element={<Login />} />
            <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={CHECK_MAIL} element={<CheckMail />} />
            <Route path={VALIDATE_USER} element={<ValidateUser />} />
            <Route path={RESET_PASSWORD} element={<ResetPassword />} />
          </Route>
        </Route>

        <Route element={<PrivateRoute />}>
          <Route element={<MainLayout />}>
            <Route path={ORGANIZATIONS} element={<OrganizationsList />} />
            <Route
              path={`${ORGANIZATIONS_DETAILS}${USERS}`}
              element={<UserList />}
            />
            <Route
              path={`${ORGANIZATIONS_DETAILS}/:id`}
              element={<OrganizationDetails />}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  </BrowserRouter>
);

export default Router;
