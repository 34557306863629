import React, { useMemo } from 'react';
import { useStore } from '@/hooks/useRedux';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {
  createSearchParams,
  NavLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { Tooltip } from 'react-tooltip';
import 'react-loading-skeleton/dist/skeleton.css';

import Button from '@/components/Button';

import { formatDate } from '@/lib/helper';

import { ORGANIZATIONS_DETAILS, USERS } from '@/src/routes/routesConstants';

import { SubscriptionTypeEnums } from '@/src/enums/enums';

import InfoIcon from '@/src/assets/imagesComponents/InfoIcon';
import UsersIcon from '@/src/assets/imagesComponents/UsersIcon';


const DetailItem = ({
  label,
  value,
  loading,
}: {
  label: string;
  value?: React.ReactNode;
  loading: boolean;
}): JSX.Element => {
  return (
    <div className="flex w-full text-base font-medium text-greyBlackLightColor pt-3">
      <div className="w-1/2 md:w-1/3">
        {!loading ? (
          <span>{label}</span>
        ) : (
          <Skeleton count={1} width={74} height={18} borderRadius={10} />
        )}
      </div>
      <div className="w-1/2 md:w-2/3">
        {!loading ? (
          <span className="text-greyBlackColor">{value}</span>
        ) : (
          <Skeleton count={1} width={175} height={18} borderRadius={10} />
        )}
      </div>
    </div>
  );
};

const OrganizationProfile = ({
  organization_id,
}: {
  organization_id: string;
}): JSX.Element => {
  const { state } = useStore();

  const isLoading = state.organizations.orgLoading;
  const orgDetails = state.organizations.orgDetails;

  const status = orgDetails?.status;

  const navigate = useNavigate();

  const [params] = useSearchParams();
  const organization_name = params.get('organization_name');

  const totalUsers = useMemo(() => {
    if (orgDetails) {
      return (
        orgDetails.user_count.active_user_count +
        orgDetails.user_count.expired_user_count +
        orgDetails.user_count.invited_user_count +
        orgDetails.user_count.inactive_user_count
      );
    }
    return 0;
  }, [orgDetails]);

  const showSubscription = useMemo(() => {
    if (orgDetails) {
      return [
        SubscriptionTypeEnums.ORGANIZATION_ADMIN,
        SubscriptionTypeEnums.ORGANIZATION_ADMIN_AND_LOAN_OFFICER,
      ].includes(orgDetails.organization_payment_type?.code);
    }
    return false;
  }, [orgDetails?.organization_payment_type]);

  const navigateToUsersList = (
    e: React.MouseEvent<HTMLAnchorElement>,
    organization_id: string
  ): void => {
    e.preventDefault();
    console.log(organization_id);
    navigate({
      pathname: `${ORGANIZATIONS_DETAILS}${USERS}`,
      search: createSearchParams({
        id: organization_id,
        organization_name: organization_name!,
      }).toString(),
    });
  };

  return (
    <div className="flex flex-col">
      <div className="shadow-pageShadow bg-white h-full flex-col rounded-lg mr-2">
        <div
          className={` bg-greyBlackColor rounded-tl-lg rounded-tr-lg  flex flex-col justify-between px-4  h-[86px]`}
        >
          <div className="flex flex-col w-full h-full justify-center">
            {!isLoading ? (
              <>
                {status != 'active' && (
                  <div className="flex justify-between">
                    <span className="text-base font-semibold capitalize text-white">
                      Organization x
                    </span>

                    <Button
                      text={status || ''}
                      className={` 
                      ${
                        status === 'pending'
                          ? 'bg-[#FBF5E2] text-[#E2B93B]'
                          : ''
                      }
                      ${
                        status === 'invited'
                          ? 'bg-[#EAECF0] text-[#344054]'
                          : ''
                      }
                       h-[22px] w-20 text-sm font-medium rounded capitalize`}
                    ></Button>
                  </div>
                )}

                {status === 'active' && (
                  <div className="flex items-center justify-between">
                    <span className="text-base font-semibold capitalize text-white">
                      {orgDetails?.name}
                    </span>
                    <Button
                      text={status}
                      className={`bg-[#CEF3E2] text-[#007B22] h-[22px] w-20  text-sm font-medium rounded capitalize`}
                    ></Button>
                  </div>
                )}
              </>
            ) : (
              <SkeletonTheme baseColor="#f2f4f71a" enableAnimation={false}>
                <div className="flex justify-between">
                  <Skeleton
                    count={1}
                    height={22}
                    width={160}
                    borderRadius={10}
                  />
                  <Skeleton
                    count={1}
                    height={22}
                    width={130}
                    borderRadius={10}
                  />
                </div>
              </SkeletonTheme>
            )}
          </div>
        </div>
        <div className="flex flex-col px-5 py-7 h-64 sm:h-72 md:h-80 lg:h-74">
          <DetailItem
            label="Admin Email"
            value={orgDetails?.admin_user.email}
            loading={isLoading}
          />
          <DetailItem
            label="NMLS"
            value={orgDetails?.organization_settings.nmls}
            loading={isLoading}
          />
          <DetailItem
            label="Payment Type"
            value={orgDetails?.organization_payment_type?.name}
            loading={isLoading}
          />
          <DetailItem
            label="Invited"
            value={
              orgDetails?.invited_at && formatDate(orgDetails.invited_at, false)
            }
            loading={isLoading}
          />

          <DetailItem
            label="Joined"
            value={
              (orgDetails?.accepted_at &&
                formatDate(orgDetails.accepted_at, false)) ||
              ''
            }
            loading={isLoading}
          />
          <DetailItem
            label="User Count"
            value={
              <>
                <Tooltip
                  className="shadow-2xl border rounded-lg"
                  style={{
                    backgroundColor: 'rgb(255, 255, 255)',
                    opacity: 1,
                    borderRadius: 10,
                  }}
                  id="user-count"
                  render={() => (
                    <div className="p-2 text-base">
                      <h6 className="text-[#344054] font-semibold mb-5">
                        User Count
                      </h6>
                      <div className="grid grid-cols-2 gap-y-3 gap-x-10">
                        <p className="text-[#344054] font-light">
                          Active : &nbsp;
                          <span className="text-[#007B22] font-semibold">
                            {orgDetails?.user_count.active_user_count}
                          </span>
                        </p>
                        <p className="text-interBlack font-light">
                          Inactive : &nbsp;
                          <span className="text-[#344054] opacity-50 font-semibold">
                            {orgDetails?.user_count.inactive_user_count}
                          </span>
                        </p>
                        <p className="text-interBlack font-light">
                          Invited : &nbsp;
                          <span className="text-[#344054] font-semibold">
                            {orgDetails?.user_count.invited_user_count}
                          </span>
                        </p>
                        <p className="text-interBlack font-light">
                          Expired : &nbsp;
                          <span className="text-[#F56A6A] font-semibold">
                            {orgDetails?.user_count.expired_user_count}
                          </span>
                        </p>
                      </div>
                    </div>
                  )}
                ></Tooltip>

                <div className="flex items-center gap-1 flex-shrink-0 w-16">
                  <span>{totalUsers}</span>
                  <div className="cursor-pointer" data-tooltip-id="user-count">
                    <InfoIcon />
                  </div>
                </div>
              </>
            }
            loading={isLoading}
          />
        </div>
      </div>
      <NavLink
        to={''}
        onClick={e => {
          if (organization_id) navigateToUsersList(e, organization_id);
        }}
      >
        <div
          className={`flex justify-center py-3 mt-10 rounded bg-greenLightColor mr-2 cursor-pointer
             text-white usersListBtn hover:border-greenLightColor hover:text-greenLightColor hover:bg-white
              border border-greenLightColor`}
        >
          <div className="mr-2 mt-1">
            <UsersIcon />
          </div>
          Users List
        </div>
      </NavLink>
    </div>
  );
};;;;

export default OrganizationProfile;
