import { Dispatch, SetStateAction, useState } from 'react';
import { useAppDispatch } from '@/hooks/useRedux';

import organizationSlice from '../../store/slice/organization.slice';

import { AxiosError } from 'axios';
import { OrganizationListBody } from '../../interface/organization.interface';

import { toast } from 'react-toastify';

import Button from '@/components/Button';

import { RemoveOrganizationService } from '../../services/organization.services';

import { REMOVE_ORGANIZATION_SUCCESS_MSG } from '@/lib/constants';

import CloseIcon from '../../../../assets/images/svg/close_icon.svg';

const RemoveOrganization = ({
  removeUserId,
  body,
  setIsRemoveModalOpen,
}: {
  removeUserId: string;
  body: OrganizationListBody;
  setIsRemoveModalOpen: Dispatch<SetStateAction<boolean>>;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleRemoveOrganization = async (): Promise<void> => {
    if (removeUserId)
      try {
        setIsLoading(true);
        const response = await RemoveOrganizationService(removeUserId);
        if (response) {
          setIsRemoveModalOpen(false);
          dispatch(organizationSlice.actions.getOrganizationsList(body));
          toast.success(REMOVE_ORGANIZATION_SUCCESS_MSG, { theme: 'colored' });
        }
      } catch (err) {
        toast.error((err as AxiosError).message, { theme: 'colored' });
      }
    setIsLoading(false);
  };

  return (
    <div className="relative transform overflow-hidden bg-white rounded-lg shadow-xl transition-all sm:my-8 w-[348px] font-Inter">
      <div className=" p-6 ">
        <div className="mt-3 text-left">
          <img
            src={CloseIcon}
            alt="*"
            className="cursor-pointer absolute right-5 top-8"
            onClick={() => setIsRemoveModalOpen(false)}
          />
          <h3
            className="text-xl font-semibold leading-6 text-blackDarkColor"
            id="modal-title"
          >
            Remove Organization
          </h3>
          <div className="mt-4">
            <p className="text-sm font-medium text-greyBlackLightColor leading-normal">
              Organization will be removed and the invite link will be no longer
              valid
            </p>
          </div>
        </div>
      </div>
      <div className=" pt-3 pb-6 flex px-6 w-full">
        <Button
          type="button"
          text="Cancel"
          className="inline-flex justify-center items-center rounded h-9 border border-greyLightColor text-sm font-medium text-interBlack w-1/2"
          onClick={() => setIsRemoveModalOpen(false)}
        />
        <Button
          type="button"
          text=" Remove"
          onClick={handleRemoveOrganization}
          disabled={isLoading}
          isLoading={isLoading}
          className="inline-flex justify-center items-center h-9 rounded bg-redButtonColor text-sm font-medium text-white ml-3 w-1/2 hover:opacity-50"
        />
      </div>
    </div>
  );
};

export default RemoveOrganization;
