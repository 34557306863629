import { combineReducers } from "redux";
import authSlice from "../modules/Authentication/store/slice/auth.slice";
import loaderSlice from "./slices/loader.slice";
import settingSlice from "@/modules/Settings/store/slice/settings.slice";
import usersSlice from "@/modules/Users/store/slice/users.slice";
import organizationSlice from "@/modules/Organizations/store/slice/organization.slice";

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  loader: loaderSlice.reducer,
  settings: settingSlice.reducer,
  users: usersSlice.reducer,
  organizations: organizationSlice.reducer,
});

export default rootReducer;
