import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';

import SubscriptionForOrgAdmin from './SubscriptionForOrgAdmin';
import SubscriptionForLoanOfficer from './SubscriptionForLoanOfficer';
import SubscriptionForOrgAdminAndLoanOfficer from './SubscriptionForOrgAdminAndLoanOfficer';

import { addOrganizationModalHeadings } from '../../../../lib/constants';
import { useAppSelector } from '../../../../hooks/useRedux';
import { RootState } from '../../../../store';
import { SubscriptionTypeEnums } from '../../../../enums/enums';

const SubscriptionPlanSettings = ({
  handleCloseModal,
  setHeaderText,
  setSubHeaderText,
}: {
  handleCloseModal: () => void;
  setHeaderText: Dispatch<SetStateAction<string>>;
  setSubHeaderText: Dispatch<SetStateAction<string>>;
}): JSX.Element => {
  const { subscriptionType } = useAppSelector(
    (state: RootState) => state.organizations
  );

  useEffect(() => {
    setHeaderText(addOrganizationModalHeadings['subscriptionPlan'].heading);
    setSubHeaderText(
      addOrganizationModalHeadings['subscriptionPlan'].subHeading
    );
  }, []);

  const handleSubscriptionTypeComponent = useMemo(() => {
    switch (subscriptionType) {
      case SubscriptionTypeEnums.ORGANIZATION_ADMIN:
        return {
          Component: (
            <SubscriptionForOrgAdmin handleCloseModal={handleCloseModal} />
          ),
        };
      case SubscriptionTypeEnums.LOAN_OFFICER:
        return {
          Component: (
            <SubscriptionForLoanOfficer handleCloseModal={handleCloseModal} />
          ),
        };

      case SubscriptionTypeEnums.ORGANIZATION_ADMIN_AND_LOAN_OFFICER:
        return {
          Component: (
            <SubscriptionForOrgAdminAndLoanOfficer
              handleCloseModal={handleCloseModal}
            />
          ),
        };

      default:
        return null;
    }
  }, [subscriptionType]);

  return <> {handleSubscriptionTypeComponent?.Component}</>;
};

export default SubscriptionPlanSettings;
