// App endpoint
export const API_ENDPOINT: string =
  import.meta.env.VITE_API_ENDPOINT || 'http://localhost:3000';

// Base url
export const BASE_URL: string = import.meta.env.VITE_API_URL;

// Firebase credentials
export const FIREBASE_API_KEY: string = import.meta.env.VITE_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN: string = import.meta.env
  .VITE_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_PROJECT_ID: string = import.meta.env
  .VITE_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET: string = import.meta.env
  .VITE_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID: string = import.meta.env
  .VITE_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID: string = import.meta.env.VITE_FIREBASE_APP_ID;
export const FIREBASE_MEASUREMENT_ID: string = import.meta.env
  .VITE_FIREBASE_MEASUREMENT_ID;

// Modules
export const AUTH: string = '/auth';
