import axiosInstance from '@/lib/interceptors';
import {
  AddSubscriptionBodyTypes,
  ApiResponse,
  InputFields,
  OrganizationDetailsResponse,
  OrganizationHistory,
  OrganizationHistoryResponse,
  OrganizationListBody,
  OrganizationListTypes,
  Plan,
} from '../interface/organization.interface';
import { BASE_URL } from '@/lib/config';
import { ENDPOINTS } from '@/lib/constants';
import { AxiosError } from 'axios';

export const ListOrganizationService = async (
  body: OrganizationListBody
): Promise<OrganizationListTypes> => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/${ENDPOINTS.ADMIN}/${ENDPOINTS.ORGANIZATION}/list`,
      body
    );
    return response?.data?.data as OrganizationListTypes;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const ResendInviteOrganizationService = async (body: {
  organization_id: string;
  email: string;
}) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/${ENDPOINTS.ADMIN}/${ENDPOINTS.ORGANIZATION}/invite/resend`,
      body
    );
    return response;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const RemoveOrganizationService = async (id: string) => {
  try {
    const response = await axiosInstance.delete(
      `${BASE_URL}/${ENDPOINTS.ADMIN}/${ENDPOINTS.ORGANIZATION}/${id}`
    );
    return response;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const CreateOrganizationService = async (body: {
  email: string;
  organization_name: string;
}) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/${ENDPOINTS.ADMIN}/${ENDPOINTS.ORGANIZATION}`,
      body
    );
    return response?.data?.data as { organization_id: string };
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const AddSubscriptionService = async (
  body: AddSubscriptionBodyTypes
) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/${ENDPOINTS.ADMIN}/subscription`,
      body
    );
    console.log('response is', response);
    return response?.status;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const getOrganizationDetails = async (id: string) => {
  try {
    const response = await axiosInstance.get<
      ApiResponse<OrganizationDetailsResponse>
    >(`${BASE_URL}/${ENDPOINTS.ADMIN}/${ENDPOINTS.ORGANIZATION}/details/${id}`);
    return response?.data?.data;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const getOrganizationHistory = async (input: {
  id: string;
  page: number;
}) => {
  try {
    const response = await axiosInstance.get<
      ApiResponse<OrganizationHistoryResponse>
    >(`${BASE_URL}/${ENDPOINTS.ADMIN}/organization-history/${input.id}`, {
      params: {
        page: input.page,
      },
    });
    return response?.data?.data;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const getOrganizationSubscription = async (id: string) => {
  try {
    const response = await axiosInstance.get<ApiResponse<Plan[]>>(
      `${BASE_URL}/${ENDPOINTS.ADMIN}/subscription/list/organization/${id}`
    );
    return response?.data?.data;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};
