import React from "react";
import LoaderSpinner from "../LoaderSpinner/LoaderSpinner";

const ScreenLoader = () => {
  return (
    <div className="w-screen h-screen bg-[#0000009C] flex justify-center items-center">
      <LoaderSpinner />
    </div>
  );
};

export default ScreenLoader;
