import { Fragment } from 'react';
import Header from '@/components/Header/Header';
import { ReactJSXElement } from 'interface/interface';
import { Outlet } from 'react-router';

const AuthLayout = () => {
	return (
		<Fragment>
			<div className="flex flex-col h-screen overflow-hidden bg-mainBackground">
				<Header />
				<Outlet />
			</div>
		</Fragment>
	);
};

export default AuthLayout;
