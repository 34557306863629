import { useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import settingSlice from '@/modules/Settings/store/slice/settings.slice';
import organizationSlice from '../../store/slice/organization.slice';

import { AxiosError } from 'axios';

import Button from '@/components/Button';
import Input from '@/components/Input';
import SelectSubscriptionTypesLoanOfficer from './SelectSubscriptionTypesLoanOfficer';
import SubscriptionPlanSettings from './SubscriptionPlanSettings';

import { CreateOrganizationService } from '../../services/organization.services';

import { InputTypes, SubscriptionTypeEnums } from '@/src/enums/enums';
import { RootState } from '../../../../store';

import { NameRegex, emailValidation } from '@/lib/validation';

import {
  ADD_ORGANIZATION_INITIAL_VALUES,
  addOrganizationModalHeadings,
} from '../../../../lib/constants';

import CloseIcon from '@/src/assets/images/svg/close_icon.svg';

const AddOrganization = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const { addOrganizationBody } = useAppSelector(
    (state: RootState) => state.organizations
  );

  const [headerText, setHeaderText] = useState<string>(
    addOrganizationModalHeadings['addOrganization'].heading
  );
  const [subHeaderText, setSubHeaderText] = useState<string>(
    addOrganizationModalHeadings['addOrganization'].subHeading
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(1);

  const formikRef: any = useRef();

  const initialValues = {
    organization_name: '',
    email: '',
  };

  const handleCloseModal = (): void => {
    dispatch(settingSlice.actions.setAddOrganizationModalState(false));
    dispatch(organizationSlice.actions.setPaymentTypesList([]));
    dispatch(
      organizationSlice.actions.setAddOrganizationBody(
        ADD_ORGANIZATION_INITIAL_VALUES
      )
    );
    dispatch(
      organizationSlice.actions.setSubscriptionType(
        SubscriptionTypeEnums.ORGANIZATION_ADMIN
      )
    );
  };

  // Handle the create organization api and to submit the values from the form
  const handleSubmit = async (values: typeof initialValues): Promise<void> => {
    setIsLoading(true);
    const body = {
      email: values.email.toLocaleLowerCase(),
      organization_name: values.organization_name.trim(),
    };
    const successToastMessage: string =
      'Organization details saved successfully';
    try {
      const response = await CreateOrganizationService(body as typeof body);
      if (response?.organization_id?.length) {
        dispatch(
          organizationSlice.actions.setAddOrganizationBody({
            ...addOrganizationBody,
            organization_id: response?.organization_id,
          })
        );
        setCurrentStep(2);
        toast.success(successToastMessage, {
          theme: 'colored',
        });
      }
    } catch (err) {
      toast.error((err as AxiosError).message, { theme: 'colored' });
    }
    setIsLoading(false);
    return;
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={addOrganizationSchema}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                touched,
                values,
              }): JSX.Element => (
                <Form className="flex flex-col w-full my-2">
                  <div className="w-full">
                    <Input
                      placeholder="organization name"
                      label="Organization Name"
                      id="organization_name"
                      name="Organization"
                      type={InputTypes.TEXT}
                      value={values.organization_name}
                      labelHide={true}
                      inputIcon={false}
                      errors={Boolean(
                        touched.organization_name && errors.organization_name
                      )}
                      helperText={
                        touched.organization_name && errors.organization_name
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{
                        borderColor: `${
                          errors.organization_name && touched.organization_name
                            ? '#FF3E1D'
                            : ''
                        }`,
                      }}
                      autoFocus
                    />
                  </div>
                  <div className="w-full mt-4">
                    <Input
                      placeholder="Email"
                      label="Admin Email"
                      id="email"
                      name="email"
                      type={InputTypes.EMAIL}
                      value={values.email}
                      labelHide={true}
                      inputIcon={false}
                      errors={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{
                        borderColor: `${
                          errors.email && touched.email ? '#FF3E1D' : ''
                        }`,
                      }}
                    />
                  </div>
                  <div className="pt-10 flex w-full">
                    <Button
                      text="Cancel"
                      type="button"
                      onClick={handleCloseModal}
                      className="inline-flex w-1/2 mr-2 h-46 border border-greyLightColor hover:border-redErrorColor hover:text-redErrorColor justify-center items-center text-interBlack text-sm rounded"
                    />

                    <Button
                      text="Continue"
                      type="submit"
                      className={`inline-flex w-1/2 ml-2 h-46 border border-buttonLightGreen ${
                        !isLoading &&
                        'hover:bg-white hover:text-buttonLightGreen'
                      }   bg-buttonLightGreen justify-center items-center text-white text-sm rounded `}
                      disabled={isLoading}
                      isLoading={isLoading}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        );

      case 2:
        return (
          <SelectSubscriptionTypesLoanOfficer
            handleCloseModal={handleCloseModal}
            setCurrentStep={setCurrentStep}
            setHeaderText={setHeaderText}
            setSubHeaderText={setSubHeaderText}
          />
        );
      case 3:
        return (
          <SubscriptionPlanSettings
            handleCloseModal={handleCloseModal}
            setHeaderText={setHeaderText}
            setSubHeaderText={setSubHeaderText}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div
      className={`relative overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all font-Inter ${
        currentStep === 4 ? 'w-[800px]' : 'w-[441px]'
      } `}
    >
      <div className="p-4">
        <div className="mt-3 text-left">
          <img
            src={CloseIcon}
            onClick={handleCloseModal}
            alt="*"
            className="cursor-pointer absolute right-5 top-8"
          />
          <h3
            className="text-xl font-semibold leading-6 text-blackDarkColor"
            id="modal-title"
          >
            {headerText}
          </h3>
          <div className="mt-4">
            <p className="text-xs font-medium text-greyBlackLightColor leading-normal">
              {subHeaderText}
            </p>
          </div>
        </div>
        <div className="flex flex-col mt-5 overflow-y-auto">{renderStep()}</div>
      </div>
    </div>
  );
};

export default AddOrganization;

const addOrganizationSchema = Yup.object().shape({
  organization_name: Yup.string()
    .required('Organization name is mandatory')
    .matches(
      NameRegex,
      'Organization name should not include any special characters or numbers'
    )
    .trim()
    .min(2, 'Organization name should be at least 2 characters long')
    .max(50, 'Organization name cannot exceed 50 characters'),
  email: Yup.lazy((value: string) => {
    if (value?.includes('@')) {
      return Yup.string()
        .email('Invalid email address')
        .max(255)
        .matches(emailValidation, 'Invalid email address')
        .required('Email is required');
    }
    return Yup.string()
      .required('Email is required')
      .matches(emailValidation, 'Invalid email address')
      .trim();
  }),
});
