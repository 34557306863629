import { call, put, takeEvery, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';

import settingSlice from '../../../Settings/store/slice/settings.slice';
import organizationSlice from '../slice/organization.slice';

import {
  GetPaymentTypesService,
  InviteOrganizationService,
  ListOrganizationService,
} from '../../services/organization.services';

import {
  OrganizationListTypes,
  OrganizationListBody,
  PaymentTypes,
  OrganizationDetailsResponse,
  OrganizationHistory,
  Plan,
  OrganizationHistoryResponse,
} from '../../interface/organization.interface';

import { InviteOrganizationBodyTypes } from '../types/organization.types';

import { SubscriptionTypeEnums } from '../../../../enums/enums';

import {
  ADD_ORGANIZATION_INITIAL_VALUES,
  INVITE_ORGANIZATION_SUCCESS_TOAST_MESSAGE,
  ORGANIZATION_LIST_BODY_INITIAL_VALUES,
} from '../../../../lib/constants';
import { RootState } from '../../../../store';
import {
  getOrganizationDetails,
  getOrganizationHistory,
  getOrganizationSubscription,
} from '../../services/organization.servces';

function* OrganizationsListSaga(action: PayloadAction<OrganizationListBody>) {
  try {
    yield put(organizationSlice.actions.setIsLoading(true));
    const response: OrganizationListTypes = yield call(
      ListOrganizationService,
      action.payload
    );
    if (response) {
      yield put(organizationSlice.actions.setOrganizationsList(response));
    }
  } catch (err) {
  } finally {
    yield put(organizationSlice.actions.setIsLoading(false));
  }
}

function* GetPaymentTypesSaga() {
  try {
    yield put(organizationSlice.actions.setIsPaymentTypesLoading(true));
    const response: PaymentTypes[] = yield call(GetPaymentTypesService);
    if (response?.length)
      yield put(organizationSlice.actions.setPaymentTypesList(response));
  } catch (err) {
  } finally {
    yield put(organizationSlice.actions.setIsPaymentTypesLoading(false));
  }
}

function* InviteOrganizationSaga(
  action: PayloadAction<InviteOrganizationBodyTypes>
) {
  try {
    const organizationListBody: OrganizationListBody | null = yield select(
      (state: RootState) => state.organizations.organizationListBody
    );
    yield put(organizationSlice.actions.isInviteOrganizationLoading(true));
    const response: number = yield call(
      InviteOrganizationService,
      action.payload
    );
    if (response)
      yield put(settingSlice.actions.setAddOrganizationModalState(false));
    yield put(organizationSlice.actions.setPaymentTypesList([]));
    yield put(
      organizationSlice.actions.setAddOrganizationBody(
        ADD_ORGANIZATION_INITIAL_VALUES
      )
    );
    yield put(
      organizationSlice.actions.setSubscriptionType(
        SubscriptionTypeEnums.ORGANIZATION_ADMIN
      )
    );
    if (organizationListBody) {
      yield put(
        organizationSlice.actions.getOrganizationsList(
          organizationListBody as OrganizationListBody
        )
      );
    } else {
      yield put(
        organizationSlice.actions.getOrganizationsList(
          ORGANIZATION_LIST_BODY_INITIAL_VALUES
        )
      );
    }

    toast.success(INVITE_ORGANIZATION_SUCCESS_TOAST_MESSAGE, {
      theme: 'colored',
    });
  } catch (err) {
  } finally {
    yield put(organizationSlice.actions.isInviteOrganizationLoading(false));
  }
}

function* getOrganizationDetailsSaga(action: PayloadAction<string>) {
  try {
    yield put(organizationSlice.actions.setOrgLoading(true));
    yield put(organizationSlice.actions.setOrgDetails(null));
    const response: OrganizationDetailsResponse = yield call(
      getOrganizationDetails,
      action.payload
    );
    if (response) {
      yield put(organizationSlice.actions.setOrgDetails(response));
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(organizationSlice.actions.setOrgLoading(false));
  }
}

function* getOrgHistorySage(
  action: PayloadAction<{
    id: string;
    page: number;
  }>
) {
  try {
    yield put(organizationSlice.actions.setOrgHistoryLoading(true));
    const response: OrganizationHistoryResponse = yield call(
      getOrganizationHistory,
      action.payload
    );

    if (response) {
      const history: OrganizationHistory[] = yield select(
        (state: RootState) => state.organizations.history
      );

      const historyPage: number = yield select(
        (state: RootState) => state.organizations.historyPage
      );

      yield put(
        organizationSlice.actions.setOrgHistory([
          ...history,
          ...response.historyDetails,
        ])
      );
      yield put(
        organizationSlice.actions.setHistoryPage({
          historyLastPage: response.lastPage,
        })
      );
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(organizationSlice.actions.setOrgHistoryLoading(false));
  }
}

function* getOrganizationPlansSaga(action: PayloadAction<string>) {
  try {
    yield put(organizationSlice.actions.setOrgPlansLoading(true));
    yield put(organizationSlice.actions.setOrgPlans([]));
    const response: Plan[] = yield call(
      getOrganizationSubscription,
      action.payload
    );

    if (response) {
      yield put(organizationSlice.actions.setOrgPlans(response));
    }
  } catch (err) {
    console.error(err);
  } finally {
    yield put(organizationSlice.actions.setOrgPlansLoading(false));
  }
}

export default function* organizationSaga() {
  yield takeEvery(
    organizationSlice.actions.getOrganizationsList.type,
    OrganizationsListSaga
  );
  yield takeEvery(
    organizationSlice.actions.getPaymentTypesList.type,
    GetPaymentTypesSaga
  );
  yield takeEvery(
    organizationSlice.actions.inviteOrganization.type,
    InviteOrganizationSaga
  );

  yield takeLatest(
    organizationSlice.actions.getOrgDetails.type,
    getOrganizationDetailsSaga
  );

  yield takeLatest(
    organizationSlice.actions.getOrgHistory.type,
    getOrgHistorySage
  );

  yield takeLatest(
    organizationSlice.actions.getOrgPlans.type,
    getOrganizationPlansSaga
  );
}
