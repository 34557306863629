import { Fragment } from 'react';

import { Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';

import SideNav from '@/components/SideNav/SideNav';
import TopNav from '@/components/TopNav/TopNav';

import { ENDPOINTS } from '@/lib/constants';

const MainLayout = (): JSX.Element => {
  const locationRoute: string = useLocation().pathname;

  const checkRoutesToApplyStyles = (): boolean => {
    if (
      locationRoute.includes(ENDPOINTS.USER) ||
      locationRoute.endsWith(ENDPOINTS.ORGANIZATIONS)
    ) {
      return true;
    }
    return false;
  };

  const shouldApplyBg: boolean = checkRoutesToApplyStyles();

  const containerClassName = `rounded-lg w-full h-full mt-5  ${
    shouldApplyBg
      ? 'shadow-pageShadow bg-white routerLayout'
      : 'shadow-none routerLay'
  }`;
  return (
    <Fragment>
      <div className="flex h-screen overflow-hidden">
        <div className="w-1/3 lg:w-1/5">
          <SideNav />
        </div>
        <div className="w-2/3 lg:w-4/5">
          <div className="flex flex-col h-full w-full px-12 pb-7 pt-8 bg-mainBackground rounded-lg">
            <TopNav />
            <div className={containerClassName}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MainLayout;
