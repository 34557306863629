import { useState } from 'react';
import { AxiosError } from 'axios';

import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import usersSlice from '../../store/slice/users.slice';

import { toast } from 'react-toastify';

import Button from '@/components/Button';

import {
  InviteUserService,
  ResendInviteService,
} from '../../service/users.service';

import { UserDetailsTypes } from '../../interface/users.interface';

import CloseIcon from '../../../../assets/images/svg/close_icon.svg';
import { useSearchParams } from 'react-router-dom';

interface Props {
  selectedOrganizationalUser: UserDetailsTypes | null;
}

const ResendInvite: React.FC<Props> = ({ selectedOrganizationalUser }) => {
  const { inviteUser } = useAppSelector(state => state.users);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const organization_id = params.get('id');

  // Open or close the modal
  const handleResendInviteModal = (isOpen: boolean) => {
    dispatch(usersSlice.actions.setIsResendInviteModalState(isOpen));
  };

  const handleResendEmail = async (): Promise<void> => {
    if (selectedOrganizationalUser) {
      const body = {
        email: selectedOrganizationalUser?.email,
        organization_user_id: selectedOrganizationalUser?.id,
        organization_id: organization_id!,
      };
      try {
        setIsLoading(true);
        const response = await ResendInviteService(body);
        if (response) {
          handleResendInviteModal(false);
          toast.success('Resent invitation successfully', { theme: 'colored' });
        }
      } catch (err) {
        toast.error((err as AxiosError).message, { theme: 'colored' });
      }
    }
    setIsLoading(false);
  };

  const handleSendInvite = async (): Promise<void> => {
    if (selectedOrganizationalUser) {
      const body = {
        email: selectedOrganizationalUser?.email,
        organization_user_id: selectedOrganizationalUser?.id,
        organization_id: organization_id!,
      };
      try {
        setIsLoading(true);
        const response = await InviteUserService(body);
        if (response) {
          handleResendInviteModal(false);
          toast.success('Invitation sent successfully', { theme: 'colored' });
        }
      } catch (err) {
        toast.error((err as AxiosError).message, { theme: 'colored' });
      }
    }
    setIsLoading(false);
  };

  return (
    <div className="relative transform overflow-hidden bg-white rounded-lg shadow-xl transition-all sm:my-8 w-[348px] font-Inter">
      <div className=" p-6 ">
        <div className="mt-3 text-left">
          <img
            src={CloseIcon}
            alt="*"
            className="cursor-pointer absolute right-5 top-8"
            onClick={() => handleResendInviteModal(false)}
          />
          <h3
            className="text-xl font-semibold leading-6 text-blackDarkColor"
            id="modal-title"
          >
            Resend Invite
          </h3>
          <div className="mt-4">
            <p className="text-sm font-medium text-greyBlackLightColor leading-normal">
              Confirming will resend the invite to the email ID{' '}
              <span className="text-gray-500 font-bold">
                {selectedOrganizationalUser?.email}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className=" pt-3 pb-6 flex px-6 w-full">
        <button
          type="button"
          className="inline-flex justify-center items-center rounded h-9 border
                 border-greyLightColor text-sm font-medium text-interBlack w-1/2 hover:border-redErrorColor hover:text-redErrorColor"
          onClick={() => handleResendInviteModal(false)}
        >
          Cancel
        </button>
        <Button
          text={'Send'}
          type="submit"
          isLoading={isLoading}
          disabled={isLoading}
          onClick={() => {
            if (inviteUser.inviteUser && !inviteUser.resendInvite) {
              handleSendInvite();
            } else {
              handleResendEmail();
            }
          }}
          className={`inline-flex w-1/2 ml-3 h-9 border border-buttonLightGreen ${
            !isLoading && 'hover:bg-white hover:text-buttonLightGreen'
          }
                bg-buttonLightGreen justify-center items-center text-white text-sm rounded`}
        />
      </div>
    </div>
  );
};

export default ResendInvite;
