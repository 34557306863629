import { useFormik } from 'formik';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';

import organizationSlice from '../../store/slice/organization.slice';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';

import { OrgAdminAndLoanOfficerSubscriptionSchema } from '../../../../lib/validation';

import { InputTypes } from '../../../../enums/enums';

import { InviteOrganizationBodyTypes } from '../../store/types/organization.types';
import { RootState } from '../../../../store';

import { SPECIAL_KEYS } from '../../../../lib/constants';

const SubscriptionForOrgAdminAndLoanOfficer = ({
  handleCloseModal,
}: {
  handleCloseModal: () => void;
}): JSX.Element => {
  const initialValues = {
    admin_plan_name: '',
    admin_price_per_month: '',
    loan_officer_plan_name: '',
    loan_officer_price_per_month: '',
  };

  const dispatch = useAppDispatch();

  const { addOrganizationBody, isInviteOrganizationLoading } = useAppSelector(
    (state: RootState) => state.organizations
  );

  const onSubmit = (): void => {
    const { organization_id, organization_payment_type_details } =
      addOrganizationBody;
    if (organization_id && organization_payment_type_details) {
      const body: InviteOrganizationBodyTypes = {
        organization_id,
        organization_payment_type_id: organization_payment_type_details?.id,
        admin_subscriptions: [
          {
            plan: values.admin_plan_name,
            price: values.admin_price_per_month,
          },
        ],
        user_subscriptions: [
          {
            plan: values.loan_officer_plan_name,
            price: values.loan_officer_price_per_month,
          },
        ],
      };
      dispatch(organizationSlice.actions.inviteOrganization(body));
    }
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: OrgAdminAndLoanOfficerSubscriptionSchema,
      onSubmit,
    });

  return (
    <form>
      <div className="h-[200px] overflow-y-auto mt-4">
        <>
          <h3 className="text-interBlack text-sm pb-1 h-6 flex items-end font-semibold">
            Organization Admin
          </h3>
          <div
            className={`flex w-full flex-col pb-1 border-sideNavButtonBg mb-2`}
          >
            <div className="flex w-full">
              <div className="w-2/5 mr-2 flex flex-col">
                <label
                  className="text-interBlack text-xs pb-1 h-6 flex items-end font-medium"
                  htmlFor="User Limit"
                >
                  Plan Name
                </label>
                <Input
                  placeholder="Plan Name"
                  label="Plan Name"
                  name="admin_plan_name"
                  id="admin_plan_name"
                  colorLabel="text-interBlack"
                  type={InputTypes.TEXT}
                  labelHide={false}
                  inputIcon={false}
                  autoFocus={true}
                  value={values.admin_plan_name}
                  errors={Boolean(
                    touched.admin_plan_name && errors.admin_plan_name
                  )}
                  helperText={touched.admin_plan_name && errors.admin_plan_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{
                    borderColor: `${
                      touched.admin_plan_name && errors.admin_plan_name
                        ? '#FF3E1D'
                        : ''
                    }`,
                  }}
                />
              </div>
              <div className="w-1/2 flex items-end">
                <div className="w-full mx-2 flex flex-col">
                  <label
                    className={` text-interBlack text-xs pb-1 h-6 flex items-end font-medium`}
                    htmlFor="User Limit"
                  >
                    Price/Month
                  </label>
                  <Input
                    placeholder="Amount"
                    label="Price/Month"
                    name="admin_price_per_month"
                    id="admin_price_per_month"
                    colorLabel="text-interBlack font-medium"
                    inputIcon={false}
                    labelHide={false}
                    type={InputTypes.TEXT}
                    value={values.admin_price_per_month}
                    errors={Boolean(
                      touched.admin_price_per_month &&
                        errors.admin_price_per_month
                    )}
                    helperText={
                      touched.admin_price_per_month &&
                      errors.admin_price_per_month
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={e => {
                      if (
                        !SPECIAL_KEYS.includes(e.key) &&
                        !e.metaKey &&
                        /\D/.test(e.key)
                      ) {
                        e.preventDefault();
                      }
                    }}
                    style={{
                      borderColor: `${
                        errors.admin_price_per_month &&
                        touched.admin_price_per_month
                          ? '#FF3E1D'
                          : ''
                      }`,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
        <>
          <h3 className="text-interBlack text-sm pb-1 h-6 flex items-end font-semibold">
            Loan Officer
          </h3>
          <div
            className={`flex w-full flex-col pb-1 border-sideNavButtonBg mb-2`}
          >
            <div className="flex w-full">
              <div className="w-1/2 mr-2 flex flex-col">
                <label
                  className="text-interBlack text-xs pb-1 h-6 flex items-end font-medium"
                  htmlFor="User Limit"
                >
                  Plan Name
                </label>

                <Input
                  placeholder="Plan Name"
                  label="Plan Name"
                  name="loan_officer_plan_name"
                  id="loan_officer_plan_name"
                  colorLabel="text-interBlack"
                  type={InputTypes.TEXT}
                  labelHide={false}
                  inputIcon={false}
                  value={values.loan_officer_plan_name}
                  errors={Boolean(
                    touched.loan_officer_plan_name &&
                      errors.loan_officer_plan_name
                  )}
                  helperText={
                    touched.loan_officer_plan_name &&
                    errors.loan_officer_plan_name
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{
                    borderColor: `${
                      touched.loan_officer_plan_name &&
                      errors.loan_officer_plan_name
                        ? '#FF3E1D'
                        : ''
                    }`,
                  }}
                />
              </div>
              <div className="w-1/2 flex items-end">
                <div className="w-full mx-2 flex flex-col">
                  <label
                    className={`text-interBlack text-xs pb-1 h-6 flex items-end font-medium`}
                    htmlFor="Price/Month"
                  >
                    Price/Month
                  </label>

                  <Input
                    placeholder="Amount"
                    label="Price/Month"
                    name="loan_officer_price_per_month"
                    id="loan_officer_price_per_month"
                    colorLabel="text-interBlack font-medium"
                    inputIcon={false}
                    labelHide={false}
                    type={InputTypes.TEXT}
                    value={values.loan_officer_price_per_month}
                    errors={Boolean(
                      touched.loan_officer_price_per_month &&
                        errors.loan_officer_price_per_month
                    )}
                    helperText={
                      touched.loan_officer_price_per_month &&
                      errors.loan_officer_price_per_month
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyDown={e => {
                      if (
                        !SPECIAL_KEYS.includes(e.key) &&
                        !e.metaKey &&
                        /\D/.test(e.key)
                      ) {
                        e.preventDefault();
                      }
                    }}
                    style={{
                      borderColor: `${
                        errors.loan_officer_price_per_month &&
                        touched.loan_officer_price_per_month
                          ? '#FF3E1D'
                          : ''
                      }`,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      </div>

      <div className="pt-5 pb-2 flex w-full relative">
        <Button
          text="Cancel"
          type="button"
          onClick={handleCloseModal}
          className="inline-flex w-1/2 mr-2 h-46 border border-greyLightColor hover:border-redErrorColor hover:text-redErrorColor 
          justify-center items-center text-interBlack text-sm rounded"
        />

        <Button
          text="Send Invite"
          type="button"
          disabled={isInviteOrganizationLoading}
          isLoading={isInviteOrganizationLoading}
          className={`inline-flex w-1/2 ml-2 h-46 border border-buttonLightGreen ${
            !isInviteOrganizationLoading &&
            'hover:bg-white hover:text-buttonLightGreen'
          }   bg-buttonLightGreen justify-center items-center text-white text-sm rounded `}
          onClick={e => {
            e.preventDefault();
            handleSubmit();
          }}
        />
      </div>
    </form>
  );
};

export default SubscriptionForOrgAdminAndLoanOfficer;
