import axiosInstance from '@/lib/interceptors';
import {
  AddSubscriptionBodyTypes,
  OrganizationListBody,
  OrganizationListTypes,
  PaymentTypes,
} from '../interface/organization.interface';
import { BASE_URL } from '@/lib/config';
import { ENDPOINTS } from '@/lib/constants';
import { AxiosError } from 'axios';
import { InviteOrganizationBodyTypes } from '../store/types/organization.types';

export const ListOrganizationService = async (
  body: OrganizationListBody
): Promise<OrganizationListTypes> => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/${ENDPOINTS.ADMIN}/${ENDPOINTS.ORGANIZATION}/list`,
      body
    );
    return response?.data?.data as OrganizationListTypes;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const ResendInviteOrganizationService = async (body: {
  organization_id: string;
  email: string;
}) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/${ENDPOINTS.ADMIN}/${ENDPOINTS.ORGANIZATION}/invite/resend`,
      body
    );
    return response;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const RemoveOrganizationService = async (id: string) => {
  try {
    const response = await axiosInstance.delete(
      `${BASE_URL}/${ENDPOINTS.ADMIN}/${ENDPOINTS.ORGANIZATION}/${id}`
    );
    return response;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const CreateOrganizationService = async (body: {
  email: string;
  organization_name: string;
}) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/${ENDPOINTS.ADMIN}/${ENDPOINTS.ORGANIZATION}`,
      body
    );
    return response?.data?.data as { organization_id: string };
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const AddSubscriptionService = async (
  body: AddSubscriptionBodyTypes
) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/${ENDPOINTS.ADMIN}/subscription`,
      body
    );
    return response?.status;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const GetPaymentTypesService = async (): Promise<PaymentTypes[]> => {
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}/${ENDPOINTS.ADMIN}/payment-types`
    );
    return response?.data?.data;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const InviteOrganizationService = async (
  body: InviteOrganizationBodyTypes
) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/${ENDPOINTS.ADMIN}/subscription`,
      body
    );
    return response?.status;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};
