import React from 'react';

export function UsersIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1666 16.5V14.8333C14.1666 13.9493 13.8154 13.1014 13.1903 12.4763C12.5652 11.8512 11.7173 11.5 10.8333 11.5H4.16658C3.28253 11.5 2.43468 11.8512 1.80956 12.4763C1.18444 13.1014 0.833252 13.9493 0.833252 14.8333V16.5M19.1666 16.5V14.8333C19.166 14.0948 18.9202 13.3773 18.4677 12.7936C18.0152 12.2099 17.3817 11.793 16.6666 11.6083M13.3333 1.60833C14.0503 1.79192 14.6858 2.20892 15.1396 2.79359C15.5935 3.37827 15.8398 4.09736 15.8398 4.8375C15.8398 5.57764 15.5935 6.29673 15.1396 6.88141C14.6858 7.46608 14.0503 7.88308 13.3333 8.06667M10.8333 4.83333C10.8333 6.67428 9.34087 8.16667 7.49992 8.16667C5.65897 8.16667 4.16658 6.67428 4.16658 4.83333C4.16658 2.99238 5.65897 1.5 7.49992 1.5C9.34087 1.5 10.8333 2.99238 10.8333 4.83333Z"
        stroke="white"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default UsersIcon;
