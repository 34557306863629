import { Navigate, Outlet } from "react-router";
import { ORGANIZATIONS, ROOT_PAGE } from "./routesConstants";
import { useAppSelector } from "@/hooks/useRedux";
import { RootState } from "../store";
import { USER_ROLES } from "@/lib/constants";
import ScreenLoader from "@/components/ScreenLoader";

const PublicRoute = (): JSX.Element => {
  const { authMeData } = useAppSelector((state: RootState) => state.auth);

  const handleRouting = (role: string): string => {
    if (role === USER_ROLES.SUPER_ADMIN) {
      return ORGANIZATIONS;
    }
    return ROOT_PAGE;
  };

  const { isAuthenticated, isAuthMeLoading } = useAppSelector((state: RootState) => state.auth);

  if (isAuthMeLoading) return <ScreenLoader />;
  else return isAuthenticated && authMeData?.role?.name ? <Navigate to={handleRouting(authMeData?.role?.name)} /> : <Outlet />;
};

export default PublicRoute;
