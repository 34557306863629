import { useFormik } from 'formik';

import { useAppDispatch, useAppSelector } from '../../../../hooks/useRedux';

import organizationSlice from '../../store/slice/organization.slice';

import Input from '../../../../components/Input';
import Button from '../../../../components/Button';

import { OrgAdminSubscriptionSchema } from '../../../../lib/validation';

import { InputTypes } from '../../../../enums/enums';

import { RootState } from '../../../../store';
import { InviteOrganizationBodyTypes } from '../../store/types/organization.types';

import { SPECIAL_KEYS } from '../../../../lib/constants';

const SubscriptionForOrgAdmin = ({
  handleCloseModal,
}: {
  handleCloseModal: () => void;
}): JSX.Element => {
  const initialValues = {
    plan_name: '',
    user_limit: '',
    price_per_month: '',
  };
  const dispatch = useAppDispatch();

  const { addOrganizationBody, isInviteOrganizationLoading } = useAppSelector(
    (state: RootState) => state.organizations
  );

  const onSubmit = (): void => {
    const { organization_id, organization_payment_type_details } =
      addOrganizationBody;
    if (organization_id && organization_payment_type_details) {
      const body: InviteOrganizationBodyTypes = {
        organization_id,
        organization_payment_type_id: organization_payment_type_details?.id,
        admin_subscriptions: [
          {
            plan: values.plan_name,
            price: values.price_per_month,
            users_limit: values.user_limit,
          },
        ],
      };
      dispatch(organizationSlice.actions.inviteOrganization(body));
    }
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: OrgAdminSubscriptionSchema,
      onSubmit,
    });

  return (
    <form>
      <div className="h-[200px] overflow-y-auto mt-4">
        <h3 className="text-interBlack text-sm pb-1 h-6 flex items-end font-semibold">
          Organization Admin
        </h3>
        <div
          className={`flex w-full flex-col pb-1 border-sideNavButtonBg mb-2`}
        >
          <div className="flex w-full">
            <div className="w-2/5 mr-2 flex flex-col">
              <label
                className="text-interBlack text-xs pb-1 h-6 flex items-end font-medium"
                htmlFor="User Limit"
              >
                Plan Name
              </label>
              <Input
                placeholder="Plan Name"
                label="Plan Name"
                name="plan_name"
                id="plan_name"
                colorLabel="text-interBlack"
                type={InputTypes.TEXT}
                labelHide={false}
                inputIcon={false}
                autoFocus={true}
                value={values.plan_name}
                errors={Boolean(touched.plan_name && errors.plan_name)}
                helperText={touched.plan_name && errors.plan_name}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{
                  borderColor: `${
                    touched.plan_name && errors.plan_name ? '#FF3E1D' : ''
                  }`,
                }}
              />
            </div>
            <div className="w-3/5 flex items-end">
              <div className="w-1/2 flex flex-col">
                <label
                  className={` text-interBlack text-xs pb-1 h-6 flex items-end font-medium`}
                  htmlFor="User Limit"
                >
                  User Limit
                </label>
                <Input
                  placeholder="Value"
                  label="User Limit"
                  name="user_limit"
                  id="user_limit"
                  colorLabel="text-interBlack"
                  labelHide={false}
                  type={InputTypes.TEXT}
                  value={values.user_limit}
                  inputIcon={false}
                  errors={Boolean(touched.user_limit && errors.user_limit)}
                  helperText={touched.user_limit && errors.user_limit}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={e => {
                    if (
                      !SPECIAL_KEYS.includes(e.key) &&
                      !e.metaKey &&
                      /\D/.test(e.key)
                    ) {
                      e.preventDefault();
                    }
                  }}
                  style={{
                    borderColor: `${
                      errors.user_limit && touched.user_limit ? '#FF3E1D' : ''
                    }`,
                  }}
                />
              </div>
              <div className="w-1/2 mx-2 flex flex-col">
                <label
                  className={` text-interBlack text-xs pb-1 h-6 flex items-end font-medium`}
                  htmlFor="User Limit"
                >
                  Price/Month
                </label>
                <Input
                  placeholder="Amount"
                  label="Price/Month"
                  name="price_per_month"
                  id="price_per_month"
                  colorLabel="text-interBlack font-medium"
                  inputIcon={false}
                  labelHide={false}
                  type={InputTypes.TEXT}
                  value={values.price_per_month}
                  errors={Boolean(
                    touched.price_per_month && errors.price_per_month
                  )}
                  helperText={touched.price_per_month && errors.price_per_month}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={e => {
                    if (
                      !SPECIAL_KEYS.includes(e.key) &&
                      !e.metaKey &&
                      /\D/.test(e.key)
                    ) {
                      e.preventDefault();
                    }
                  }}
                  style={{
                    borderColor: `${
                      errors.price_per_month && touched.price_per_month
                        ? '#FF3E1D'
                        : ''
                    }`,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5 pb-2 flex w-full relative">
        <Button
          text="Cancel"
          type="button"
          onClick={handleCloseModal}
          className="inline-flex w-1/2 mr-2 h-46 border border-greyLightColor hover:border-redErrorColor hover:text-redErrorColor 
          justify-center items-center text-interBlack text-sm rounded"
        />

        <Button
          text="Send Invite"
          type="button"
          disabled={isInviteOrganizationLoading}
          isLoading={isInviteOrganizationLoading}
          className={`inline-flex w-1/2 ml-2 h-46 border border-buttonLightGreen ${
            !isInviteOrganizationLoading &&
            'hover:bg-white hover:text-buttonLightGreen'
          }   bg-buttonLightGreen justify-center items-center text-white text-sm rounded `}
          onClick={e => {
            e.preventDefault();
            handleSubmit();
          }}
        />
      </div>
    </form>
  );
};

export default SubscriptionForOrgAdmin;
