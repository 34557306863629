import { useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Button from '@/components/Button';
import Input from '@/components/Input';

import { LOGIN } from '@/src/routes/routesConstants';

import { InputTypes } from '@/src/enums/enums';

import { emailValidation } from '@/lib/validation';

import Logo from '@/src/equity_release_logo.svg';
import { useDispatch } from 'react-redux';
import authSlice from '../../store/slice/auth.slice';
import useLoading from '@/hooks/useLoading';

const ForgotPassword = (): JSX.Element => {
  const initialValues = {
    email: '',
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoading = useLoading(authSlice.actions.forgotPassword.type);

  const formikRef: any = useRef();

  const handleSubmit = async (values: typeof initialValues): Promise<void> => {
    const { email } = values;
    dispatch(authSlice.actions.forgotPassword({ email, navigate }));
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={forgotPasswordSchema}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          touched,
          values,
        }): JSX.Element => (
          <Form className="m-auto w-[367px] shadow-card px-6 py-8 flex flex-col justify-center items-center rounded-lg font-Inter bg-white">
            <img className="w-9 h-9" src={Logo} alt="*" />
            <h1 className="text-xl text-blackDarkColor font-semibold pt-4">
              Forgot Password?
            </h1>
            <small className="text-xs font-medium text-greyBlackLightColor mt-2">
              No worries, we’ll send you instructions.
            </small>

            <div className="w-full mt-8">
              <Input
                placeholder="Enter your email"
                label="Email"
                labelHide={true}
                inputIcon={false}
                id={InputTypes.EMAIL}
                name={InputTypes.EMAIL}
                type={InputTypes.TEXT}
                value={values.email}
                errors={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                onChange={handleChange}
                onBlur={handleBlur}
                colorLabel="text-interBlack"
                style={{
                  borderColor: `${
                    touched.email && errors.email ? '#FF3E1D' : ''
                  }`,
                }}
                autoFocus
              />

              <Button
                text="Reset Password"
                type="submit"
                disabled={isLoading}
                isLoading={isLoading}
                className={`inline-flex w-full mt-7 mb-4 h-9 border border-buttonLightGreen ${
                  !isLoading && 'hover:bg-white'
                }  hover:text-buttonLightGreen bg-buttonLightGreen justify-center items-center text-white text-sm rounded`}
              />
            </div>

            <NavLink to={LOGIN}>
              <a className="text-greenLightColor cursor-pointer text-xs font-semibold ">
                Back to login
              </a>
            </NavLink>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ForgotPassword;

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.lazy((value: string) => {
    if (value?.includes('@')) {
      return Yup.string()
        .email('Enter a valid email address')
        .max(255)
        .matches(emailValidation, 'Enter a valid email address')
        .required('Email is required');
    }

    return Yup.string()
      .required('Email is required')
      .matches(emailValidation, 'Enter a valid email address')
      .trim();
  }),
});
