export const ROOT_PAGE = '/';
export const LOGIN = '/login';
export const ORGANIZATIONS = '/organizations';
export const ORGANIZATIONS_DETAILS = `${ORGANIZATIONS}/details`;
export const CHECK_MAIL = '/check-mail';
export const FORGOT_PASSWORD = '/forgot-password';
export const VALIDATE_ORGANIZATION_ADMIN = '/organization/validate';
export const VALIDATE_USER = '/user/validate';
export const RESET_PASSWORD = '/reset-password';
export const routesArray = [ORGANIZATIONS, ORGANIZATIONS_DETAILS];
export const USERS = '/users';
export const USER_DETAILS = `${USERS}/details`;

