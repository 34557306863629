import React from 'react';

const InfoIcon = () => {
  return (
    <svg
      className="scale-120"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_11009_6406)">
        <path
          d="M4.99992 3.33331V4.99998M4.99992 6.66665H5.00409M9.16659 4.99998C9.16659 7.30117 7.30111 9.16665 4.99992 9.16665C2.69873 9.16665 0.833252 7.30117 0.833252 4.99998C0.833252 2.69879 2.69873 0.833313 4.99992 0.833313C7.30111 0.833313 9.16659 2.69879 9.16659 4.99998Z"
          stroke="#5C677E"
          stroke-width="0.8"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11009_6406">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InfoIcon;
